@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

html {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Locally stored fonts (must be placed in static/fonts folder)
$local-fonts: (
    "barlow" : (
        700: "barlow-bold",
        900: "barlow-black"
    ),
    "nexa" : (
        300: "nexa_light",
        700: "nexa_bold",
    )
) !default;

@function ends-with($string, $find) {
    @return str-length($string) >= str-length($find) AND str-slice($string, (str-length($string) - str-length($find) + 1)) == $find;
}

@mixin font-face($font-family, $font-style) {
    @each $font-weight, $font-name in $font-style {
        @font-face {
            font-family: $font-family;
            font-style: if(ends-with($font-name,'-i'), italic, normal);
            font-weight: $font-weight;
            src: url("../fonts/#{$font-name}.eot"),
                url("../fonts/#{$font-name}.eot?#iefix") format("embedded-opentype"),
                url("../fonts/#{$font-name}.woff2") format("woff2"), url("../fonts/#{$font-name}.woff") format("woff"),
                url("../fonts/#{$font-name}.ttf") format("truetype"), url("../fonts/#{$font-name}.svg##{$font-name}") format("svg");
        }
    }
}

@each $font-family, $font-style in $local-fonts {
	@include font-face($font-family, $font-style);
}