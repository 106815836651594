table {
    border:none;
    * { border: none;}
    &:not([class*="w-"]) {
        width: 100%;
    }

    thead {
        border-bottom: 2px solid $table-header-row-border-bottom-color;
        th {
            @include header($table-header-value);
        }
    }

    tr {
        border-bottom: 1px solid $table-body-row-border-bottom-color;

        .modal &,
        .content-asset & {
            &:hover {
                background: rgba($brand-primary, .05);
            }
        }
    }
    tbody {
        tr {
            &:last-child {
                border: none;
            }
        }
        td {
            overflow-wrap: anywhere;
        }
    }
}