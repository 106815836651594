.compare-bar-wrapper {
    border-top: 1px solid map-get($border, secondary);
    bottom: 0;
    display: none;
    position: fixed;
    width: 100%;
    z-index: calc(#{$z-index-sticky-footer} + 1);
    background-color: $white;
    padding: $spacer 0;

    .compare-bar-slot-container {
        align-items: center;
        flex-wrap: nowrap;
        @include media-breakpoint-up(md) {
            min-height: $plp-product-compare-image-max-height;
            justify-content: space-between;
        }
    }

    .product-slots {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
        }
        height: auto;
        justify-content: space-evenly;
        align-items: center;
        flex-grow: 1;
        transition: all $transition-speed ease-in-out;

        .selected-product {
            display: flex;
            transition: all $transition-speed ease-in-out;
            max-width: $plp-product-compare-image-max-height;

            &:not(:first-child) {
                margin-left: $spacer;
            }

            &:has([data-pid]) {
                display: flex;
            }

            &:has(.min-products-msg) {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: none;
            }

            @-moz-document url-prefix() {
                .alert {
                    min-width: max-content; //firefox fix
                    left: -100%; //firefox fix
                }
            }

            .remove-btn {
                @include delete-card-button;
                left: unset;
                right: unset;
                top: unset;
                bottom: unset;
                margin: 0;
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    .slot {
        background-color: transparent;
        width: auto;
        border-radius: $border-radius;
        display: flex;
        align-items: flex-start;
        min-height: $plp-product-compare-image-max-height;

        &:not([data-pid]) {
            border: 1px dashed #ccc;
            align-items: center;
        }

        img {
            align-self: center;
            border-radius: $border-radius 0 0 $border-radius;
            max-height: $plp-product-compare-image-max-height;
        }
    }
}

.product-comparison {
    overflow: scroll;
    padding-bottom: $spacer-xl;

    .compare-parent {
        display: flex;
        flex-flow: row nowrap;
        height: auto;
        width: 100%;
        margin-left: 0;

        .compare-child {
            padding: $spacer-sm;
            @include media-breakpoint-up(md) {
                padding: $spacer;
            }
            height: auto;
            width: 100%;
            min-width: $product-compare-item-min-width;

            &:first-child {
                position: sticky;
                left: 0;
                z-index: 1;
                text-align: right;
                font-weight: bold;
                max-width: 20vw;
                @include media-breakpoint-down(sm) {
                    max-width: 30vw;
                    min-width: 30vw;
                }
            }

            &:nth-child(2) {
                padding-left: #{$spacer-sm * 2};
            }
            .product {
                height: 100%;
            }

            .product-tile {
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: space-between;

                .badge-product-container {
                    display: none;
                }
                .show-number-and-average {
                    .bv_main_container {
                        .bv_stars_component_container {
                            .bv_stars_svg_no_wrap {
                                padding: 0px !important; // needed to overwrite crazy bv styles
                            }
                        }
                        //show the bv ratings and full info
                        .bv_averageRating_component_container,
                        .bv_numReviews_component_container {
                            display: block !important; //needed to overwrite crazy bv styles
                        }
                    }
                }
                .tile-postimage {
                    flex-direction: column;
                    align-items: self-start;
                    height: auto;
                }
                .compare-non-color-attributes {
                    flex-basis: calc(#{$button-padding-top + $button-padding-bottom + $button-line-height});
                }
                .non-color-attribute-swatches {
                    .non-input-label {
                        display: none;
                    }
                    @include clearfix();
                    .swatch-tile-container {
                        float: left;
                        display: block;
                        margin: calc(#{$pdp-non-color-swatch-spacing} / 2) $pdp-non-color-swatch-spacing calc(#{$pdp-non-color-swatch-spacing} / 2) 0;
                        .swatch {
                            background: none;
                            display: block;
                            position: relative;
                            padding: 0;
                            border: none;
                            .swatch-value {
                                @include swatch-non-color($pdp-non-color-swatch-min-width);
                            }
                        }
                    }
                }
            }
            .remove-btn {
                + .product {
                    .product-tile {
                        .tile-preimage {
                            justify-content: flex-end; //moving things to the right since the remove button is always on the left
                        }
                    }
                }
            }
        }

        &.specs {
            width: var(--compare-container-width); //keeps sticky bar on page for mobile
            @include media-breakpoint-up(md) {
                width: 100%;
            }

            .compare-child {
                background-color: $white;

                &:first-child {
                    @at-root .overflown#{&} {
                        &:after {
                            content: '';
                            display: inline-block;
                            width: $spacer;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: calc(#{$spacer} * -1);
                            background-image: linear-gradient(to right, rgba($black, .25), rgba($black, 0));
                            background-position: right center;
                            background-repeat: no-repeat;
                            background-size: #{$spacer * 4} 100%;
                            background-attachment: scroll;
                        }
                    }
                }
            }

            .compare-child {
                border-bottom: 1px solid $brand-secondary;
            }

            &:hover {
                &> .compare-child {
                    &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($off-white, .75);
                        mix-blend-mode: multiply;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        &:not(.specs) {
            .compare-child {
                .remove-btn {
                    @include delete-card-button;
                    left: unset;
                    right: unset;
                    top: unset;
                    bottom: unset;
                    margin: 0;
                }
            }
        }
    }
    &.overflown {
        width: calc(100vw - #{$spacer});
    }
}

//container product-comparison is in. Add a dropshadow on the right side of the screen to indicate more content
.product-comparison-container {
    &.has-overflown {
        &:after {
            content: '';
            display: block;
            width: $spacer;
            height: 100%;
            position: fixed;
            top: 0;
            right: 0;
            background-image: linear-gradient(to right, rgba($black, 0),rgba($black, .075));
            background-position: right center;
            background-repeat: no-repeat;
            background-size: $spacer 100%;
            background-attachment: scroll;
        }
    }
}