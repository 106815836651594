.product-tile {
    position: relative;

    .product-grid & {
        padding-bottom: $product-tile-grid-margin-bottom;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    // Tile Images
    .image-container {
        position: relative;
        margin-bottom: $product-tile-image-margin-bottom;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        &:before {
            //this sets the height to match the width. Useful when there are full rows of no images
            content: '';
            margin-top: 100%;
            display: block;
        }
    }
    .tile-image {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .product-tile-image-link {
        display: block;
    }
    .quick-action-btns {
        display: flex;
        align-items: center;
        .swatch-color-count-only {
            display: none;
        }
        .product-grid-small & {
            white-space: nowrap;
        }
    }
    .quickview {
        text-decoration: none;
        .quickview-icon {
            width: $product-tile-quickview-link-size;
            height: $product-tile-quickview-link-size;
            line-height: $product-tile-quickview-link-size;
            background-color: $product-tile-quickview-link-background-color;
            box-shadow: $product-tile-quickview-link-box-shadow;
            border-radius: 50%;
            position: relative;
            right: 0px;
            @include icon($product-tile-quickview-link-icon, before, $product-tile-quickview-link-icon-size, $product-tile-quickview-link-color);
        }
        .quickview-icon-label {
            margin-left: $spacer-sm;
            color: $product-tile-quickview-link-color;
            font-size: $product-tile-quickview-link-font-size;
            text-decoration: $product-tile-quickview-link-text-decoration;
        }
        &.btn {
            flex: 1 1 50%;
            opacity: 0;
        }
    }

    .quick-add-to-cart:not(.bundle-builder-add-to-cart),
    .single-variant-quick-add-to-cart:not(.single-variant-bundle-builder-add-to-cart) {
        text-decoration: none;
        align-items: stretch; //fix for no-image height variations
        .icon-add-to-cart {
            width: $product-tile-quickview-link-size;
            height: $product-tile-quickview-link-size;
            line-height: $product-tile-quickview-link-size;
            background-color: $product-tile-quickview-link-background-color;
            box-shadow: $product-tile-quickview-link-box-shadow;
            border-radius: 50%;
            &:before {
                font-size: $product-tile-quick-add-to-cart-link-icon-size;
                //margin-left: 0.425em;
            }
            .path2:before {
                font-size: $product-tile-quick-add-to-cart-link-icon-size * 1.5;
                margin-left: -0.9em;
            }
            .path3:before {
                font-size: $product-tile-quick-add-to-cart-link-icon-size * 2;
                margin-left: -1.015em;
                bottom: -0.06em;
                position: relative;
            }
        }
        .quick-add-to-cart-icon-label {
            margin-left: $spacer-sm;
            color: $product-tile-quickview-link-color;
            font-size: $product-tile-quickview-link-font-size;
            text-decoration: $product-tile-quickview-link-text-decoration;
        }
        &.btn {
            flex: 1 1 50%;
            opacity: 0;
            padding-left: 0;
            padding-right: 0;
            &:not(.d-none) + .quickview {
                margin-left: $product-tile-quick-action-buttons-spacer;
            }
        }
        &:not(.d-none) + .quickview {
            @include media-breakpoint-up(lg) {
                margin-left: $product-tile-quick-action-buttons-spacer;
            }
        }
    }

    .single-variant-bundle-builder-add-to-cart {
        flex-basis: auto;
        margin-top: $spacer;
    }

    .tile-preimage,
    .tile-postimage {
       display: flex;
       align-items: center;
       margin-bottom: $product-tile-pre-post-image-margin-bottom;
       height: $product-tile-pre-post-image-default-height;

       .badge-product-container.badge-image-over {
            position: unset;
            margin: 0px;
       }
    }
    .tile-postimage {
        margin-bottom: $spacer-sm;
        align-items: center;
    }

    .product-tile-image-container {
        max-height: $product-tile-image-container-max-height;
    }

    .product-list-enhancements-toggle-product {
        &.product-tile-wishlist-icon {
            position: relative;
            top: unset;
            right: unset;
            margin-left: auto;
        }
        .product-list-enhancements-icon {
            height: unset;
            width: unset;
            line-height: normal;
            &.before:before {
                position: relative;
                top: unset;
                right: unset;
                transform: translate(0, 0);
            }
        }
    }


    // Tile Body
    .tile-body {
        padding-top: $spacer-sm;
        padding-bottom: $spacer;
        position: relative;
        display: flex;
        flex-flow: column;
        flex: 1 0 auto;
        :last-child {
            margin-bottom: 0;
        }
        &.align-items-center {
            text-align: center;
        }
        &.align-items-end {
            text-align: right;
        }
    }
    .color-swatches {
        margin-bottom: $product-tile-swatch-margin-bottom;
        font-size: 0;
        display: flex;
        align-items: center;
        .swatches {
            @include clearfix;
        }
        .swatch-link {
            display: block;
            float: left;
            text-decoration: none;
            padding: 4px;
            cursor: default;

            &.swatch {
                &-hide {
                    display: none;
                }
                &-md-hide {
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }
                &-show {
                    display: block;
                }
                &-md-show {
                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }
            }
        }
        .swatch-circle {
            @include swatch($product-tile-swatch-size, $white);
            margin-right: $product-tile-swatch-spacing;
            border: $color-swatch-border;
            cursor: pointer;

            &:hover {
                box-shadow: $color-swatch-sm-hover-shadow;
                border: $color-swatch-sm-border;
            }
            &.selected {
                box-shadow: $color-swatch-sm-selected-shadow;
                border: $color-swatch-sm-border;
            }
            &.unselectable,
            &.unavailable {
                @include disabled;
                pointer-events: auto;
                position: relative;
            }
            &.unselectable {
                @include strike-through-swatch($product-tile-swatch-size, transparent, map-get($border, secondary));
                box-shadow: $color-swatch-sm-disabled-shadow;
                border: $color-swatch-sm-border;
                cursor: not-allowed;
            }
            &:active {
                border: $color-swatch-sm-border;
                box-shadow: $color-swatch-sm-active-shadow;
            }
        }
        .swatch-thumbnail {
            @include swatch($product-tile-swatch-thumbnail-size, $white, 'thumbnail');
            margin-right: $product-tile-swatch-thumbnail-spacing;
            border: $color-swatch-sm-border;
            &:hover {
                box-shadow: $color-swatch-sm-hover-shadow;
                border: $color-swatch-sm-border;
            }
            &.selected {
                box-shadow: $color-swatch-thumbnail-selected-shadow;
                border: $color-swatch-selected-border;
            }
            &.unselectable {
                @include strike-through-swatch(calc(100% + 15px), $gray, $white);
                border: $color-swatch-sm-border;
                box-shadow: $color-swatch-thumbnail-selected-shadow;
                cursor: not-allowed;
            }
            &:active {
                border: $color-swatch-sm-border;
                box-shadow: $color-swatch-active-shadow;
            }
        }
        .swatch-color-count {
            font-size: $product-tile-swatch-color-count-font-size;
            line-height: $product-tile-swatch-size;
            letter-spacing: $product-tile-swatch-color-count-letter-spacing;
            margin-left: calc(#{$spacer} / 2);
            white-space: nowrap;
            display: inline-block;
            vertical-align: middle;
            color: $product-tile-swatch-color-count-color;
            text-decoration: $product-tile-swatch-color-count-text-decoration;
            display: flex;
            &:after {
                content: attr(data-extra-colors-mobile);
                letter-spacing: .2em;
                    @include media-breakpoint-up(md) {
                        content: attr(data-extra-colors);
                    }
            }
            span {
                order: 2;
                &.plus {
                    order: 0;
                }
            }
        }
    }
    .pdp-link {
        margin-bottom: $product-tile-title-margin-bottom;
        flex: 1 0 auto;
        .link {
            display: block;
            @include header($product-tile-title-header-value);
            color: $product-tile-title-color;
            text-decoration: $product-tile-title-text-decoration;
            overflow-wrap: anywhere;
            &:hover {
                color: $product-tile-title-color-hover;
                text-decoration: $product-tile-title-text-decoration;
            }
        }
        .subtitle,
        .subtitle > * {
            font-weight: $product-tile-subtitle-font-weight;
            font-size: $product-tile-subtitle-font-size;
            margin-top: $product-tile-subtitle-margin-top;
            color: $product-tile-subtitle-color;
        }
    }
    .price {
        margin-bottom: $product-tile-price-margin-bottom;
        font-size: $product-tile-price-font-size;
        color: $product-tile-price-color;
        flex: 0 0 auto;
        @include clearfix;
        @include media-breakpoint-up(lg) {
            font-size: $product-tile-price-font-size;
            line-height: $default-text-line-height;
        }
        del,
        .strike-through {
            color: $product-tile-price-strike-through-color;
            font-size: $product-tile-price-strike-through-font-size;
        }
        del ~ .sales {
            margin-right: $spacer-sm;
            color: $product-tile-sale-color;
        }
        .percent-off {
            font-size: $product-percent-off-price-font-size;
            text-decoration: none;
            color: $product-percent-off-price-color;
            padding-left: $spacer-sm;
        }
    }
    .promotion {
        margin-bottom: $product-tile-promotion-margin-bottom;
        color: $product-tile-promotion-color;
        font-size: $product-tile-promotion-font-size;
        flex: 0 0 auto;
        .tooltip-icon {
            display: none;
        }
    }
    .ratings {
        margin-bottom: $product-tile-rating-margin-bottom;
        font-size: $product-tile-rating-star-size;
        color: $product-tile-rating-star-color;
        max-width: 100%;
        flex: 0 0 auto;
        .fa-star {
            @include icon($icon-star);
        }
        .fa-star-half-o {
            @include icon($icon-star-half);
        }
        .fa-star-o {
            @include icon($icon-star-empty);
        }

        .bv-ratings-count,
        .bv-rating-stars-rating {
            display: none;
        }
        .bv-rating-stars {
            margin-top: calc(#{$spacer} / 2);
        }
    }
    &.on-hover {
        @include media-breakpoint-up(lg) {
            .color-swatches {
                .swatches {
                    opacity: 0;
                }
            }
            &:hover .color-swatches .swatches {
                opacity: 1;
            }
        }
    }

    .show-secondary-image {
        @include media-breakpoint-up(md) {
            &:hover {
                .product-tile-image {
                    display: none;
                }
                .product-tile-image-secondary {
                    display: block;
                }
            }
        }
        .product-tile-image {
            display: block;
        }

        .product-tile-image-secondary {
            display: none;
        }
    }

    //plpHoverEffects
    &.bottom-border {
        &:hover {
            .product-tile-image-container:after {
                content: '';
                @include absolute-cover;
                pointer-events: none;
                box-shadow: $product-tile-image-hover-shadow;
            }
        }
    }
    &.zoom-image {
        .image-container {
            overflow: hidden;
        }
        .product-tile-image-link {
            transition: transform $hero-transition-speed-medium ease-in-out;
        }
        &:hover {
            .product-tile-image-link {
                transform: $product-tile-image-transformation;
            }
        }
    }
    &.underline-title {
        &:hover {
            .tile-body {
                .pdp-link {
                    .link {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &:hover {
        .quick-action-btns {
            .btn {
                opacity: 1;
            }
        }
    }

    // Small Style
    .product-grid-small & {
        .pdp-link {
            .link {
                font-family: $product-tile-sm-title-font;
                font-size: $product-tile-sm-title-font-size;
                font-weight: $product-tile-sm-title-font-weight;
                text-transform: $product-tile-sm-title-text-transform;
            }
        }
        .promotion,
        .ratings,
        .tile-preimage,
        .tile-postimage {
            display: none;
        }
        .quick-action-btns {
            .swatch-color-count-only {
                display: none;
                font-size: $product-tile-swatch-color-count-font-size;
                line-height: $product-tile-swatch-size;
                letter-spacing: $product-tile-swatch-color-count-letter-spacing;
                margin-left: calc(#{$spacer} / 2);
                white-space: nowrap;
                vertical-align: middle;
                color: $product-tile-swatch-color-count-color;
                text-decoration: $product-tile-swatch-color-count-text-decoration;
            }
        }
    }
}

//suggested product slider styling
.product-grid-small {
    .slider {
        display: flex;
        .product {
            height: 100%;
            .product-tile {
                display: flex;
                flex-flow: column wrap;
                height: 100%;
                align-items: stretch;
                justify-content: space-between;
            }
        }
    }
}
// BAZAARVOICE OVERRIDES
.page {
    .product-tile {
        .BVInlineRatings.pdp-inline-bv-rating-drawer { display: none;}
        [data-bv-show=inline_rating] {
            .bv_main_container {
                .bv_numReviews_component_container,
                .bv_averageRating_component_container {
                    display: none !important;
                }
                .bv_stars_component_container {
                    .bv_stars_svg_no_wrap {
                        padding-top: calc(#{$spacer-sm * 2}) !important;
                        svg {
                            padding-right: $product-tile-rating-star-padding !important
                        }
                    }
                }
            }
        }
    }
}
