@import "compare";

// Search tabs
.search-nav {
    margin-top: $spacer;
    margin-bottom: $spacer;
    padding-left: $spacer;
    padding-right: $spacer;
    border-bottom: 1px solid $gray;

    //Specific for Search Pages - prevents tabs from showing, but allows tab from an ADA perspective to be compliant
    &.hide-search-tabs {
        display: none;
    }

    .nav-tabs-wrapper {
        padding: 0;
        width: 100%;
        .nav-link.active {
            background-color: $white;
        }
        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }
}

// Shared search styles
.search-results {
    .show-more,
    .show-more-content {
        padding: $spacer 0;
        clear: both;
    }
    .search-keywords {
        font-weight: bold;
    }
    .category-item {
        margin-bottom: $spacer;
    }

    .tab-pane.active:focus {
        outline: none;
    }
}

// Grid header
.grid-header,
.content-grid-header {
    margin-bottom: $spacer;
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
    .breadcrumb-container {
        &>.row {
            align-items: center;
            height: 100%;
        }
    }
    .breadcrumb {
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
    .result-count {
        p {
            margin-bottom: $spacer;
            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }
        span {
            color: $product-refinement-result-count-color;
        }
    }
    .filter-results {
        width: 100%;
    }
    .product-sort {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        label {
            font-size: $default-text-font-size;
            white-space: nowrap;
            padding-right: $spacer;
            color: $dark-gray;
            height: $input-height;
            line-height: $input-height;
            margin-bottom: 0;
        }
        select, .label-css {
            @include media-breakpoint-down(md) {
                @include button(secondary);
                background-size: $dropdown-icon-size;
                height: calc(#{$button-line-height} + #{$input-padding-top} + #{$input-padding-bottom} + (#{$input-border-width}*2));
                max-width: calc(100% - (#{map-get($grid-gutter-widths, xs)} / 2));
            }
            @include media-breakpoint-up(lg) {
                font-size: $default-text-font-size;
                background-size: $dropdown-icon-size;
                color: $black;
                border: none;
                width: auto;
                font-weight: $product-filter-sort-order-font-weight;
            }
        }
    }
}

.product-grid {
    .product {
        height: 100%;
    }
}

// Filters
.filter-header {
    margin-left: -$spacer;
    margin-right: -$spacer;
}
.header-bar {
    background-color: $product-refinement-header-background-color;
    border-bottom: 1px solid $product-refinement-header-border-color;
    padding: $product-refinement-header-padding;
    button.close {
        @include icon($icon-close, after, 16px, $product-refinement-header-color);
        line-height: 75%;
        margin: 0;
        padding: 0;
        text-shadow: none;
        opacity: 1;
        span {
            display: none;
        }
    }
    .result-count {
        @include header($product-refinement-header-title-size);
        color: $product-refinement-header-color;
    }
}
.filter-bar {
    ul {
        padding-left: $spacer;
        margin: 0;
        @include media-breakpoint-up(xl) {
            padding-left: $spacer-xl;
        }
    }
    li {
        list-style: none;
        float: left;
        margin-right: $product-filter-spacing;
        margin-bottom: $spacer;
        &:not(:first-child) {
            margin-left: $product-filter-spacing;
        }
        &.filter-value {
            position: relative;
            button {
                color: $product-filter-color;
                background: $product-filter-background;
                font-size: $product-filter-font-size;
                font-weight: $product-filter-font-weight;
                line-height: 1;
                border: $product-filter-border;
                border-radius: $product-filter-border-radius;
                padding-top: $product-filter-padding-top;
                padding-right: $product-filter-padding-right;
                padding-bottom: $product-filter-padding-bottom;
                padding-left: $product-filter-padding-left;
                @include icon($icon-close, after, $product-filter-icon-size);
                @include label-styles();

                &:after {
                    @include vertical-center;
                    right: $product-filter-padding-right - $product-filter-icon-size - $product-filter-padding-left;
                    opacity: 1;
                }
                &:hover {
                    color: darken($product-filter-color, 15%);
                }
            }
        }
    }
    .result-count {
        padding: $spacer/2 0;
        margin-left: auto;
        margin-bottom: $spacer;
        margin-right: $spacer;
        @include media-breakpoint-up(xl) {
            margin-right: $spacer-xl;
        }
        span, p {
            margin-bottom: 0;
            color: $product-refinement-result-count-color;
        }
    }
}

// Refinements
.refinement-bar {
    overflow: auto;
    &-horizontal {
        @include media-breakpoint-up(lg) {
            top: unset !important;
        }
        overflow: visible;
    }
    @include media-breakpoint-up(lg) {
        &:not([class*="refinement-bar-horizontal"]) {
            margin-top: $spacer;
            &+div {
                margin-top: $spacer;
            }
        }
    }
    ul {
        padding: 0;
        margin: 0;
        line-height: 1;
    }
    .secondary-bar {
        @include remove-list-styling();
        @include media-breakpoint-up(lg) {
            margin-bottom: $spacer;
        }
        border-width: $product-refinement-secondary-bar-border-width;
        border-style: $product-refinement-secondary-bar-border-style;
        border-color: $product-refinement-secondary-bar-border-color;

        .secondary-bar-links {
            text-decoration: $product-refinement-secondary-link-text-decoration;
            line-height: $product-refinement-secondary-link-line-height;
            font-size: $product-filter-font-size;
            padding: $product-refinement-secondary-link-padding;
            display: block;
            width: 100%;
            color: $product-refinement-secondary-link-text-color;
        }
        li:last-child .secondary-bar-links {
            text-align: right;
        }
    }
    @include media-breakpoint-down(md) {
        position: fixed;
        transform: translateX(-100%);
        transition: transform $transition-speed ease-out;
        top: 0;
        left: 0;
        display: block;
        width: $product-refinements-drawer-max-width;
        height: calc(100vh - #{$header-height-md});
        background-color: $white;
        z-index: $z-index-refinement-drawer;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: $product-refinements-drawer-padding-bottom;
        &.in {
            transform: translateX(0);
        }
    }
    @include media-breakpoint-down(sm) {
        height: calc(100vh - #{$header-height});
    }
    @include media-breakpoint-down(xs) {
        width: $product-refinements-drawer-width;
        max-width: $product-refinements-drawer-max-width;
    }
    .product-sort {
        display: flex;
        align-items: center;
        label {
            font-size: $default-text-font-size;
            white-space: nowrap;
            padding-right: $spacer;
            color: $dark-gray;
            height: $input-height;
            line-height: $input-height;
            margin-bottom: 0;
        }
        select, .label-css {
            @include media-breakpoint-down(md) {
                @include button(secondary);
                height: calc(#{$button-line-height} + #{$input-padding-top} + #{$input-padding-bottom} + (#{$input-border-width}*2));
            }
            @include media-breakpoint-up(lg) {
                @include button();
                font-size: $default-text-font-size;
                background-size: $dropdown-icon-size;
                color: $black;
                border: none;
                width: auto;
                font-weight: $product-filter-sort-order-font-weight;
                height: $product-refinement-horizontal-multipledropdowns-height;
                border-top: $product-refinement-horizontal-multipledropdowns-btn-border-top;
                border-bottom: $product-refinement-horizontal-multipledropdowns-btn-border-bottom;
                border-left: $product-refinement-horizontal-multipledropdowns-btn-border-left;
                border-right: $product-refinement-horizontal-multipledropdowns-btn-border-right;
                padding-top: $product-refinement-horizontal-multipledropdowns-btn-padding-top;
                padding-bottom: $product-refinement-horizontal-multipledropdowns-btn-padding-bottom;
                padding-left: $product-refinement-horizontal-multipledropdowns-btn-padding-left;
                text-align: left;
            }

        }

    }
}

.label-css {
    @include media-breakpoint-down(sm) {
    //Overriding position: relative from the parent class
    position: absolute !important;
    font-weight: bold;
    }
  }
  .select-css {
    @include media-breakpoint-down(sm) {
         opacity: 0;
    }
}
.refinements {
    .refinement:not(.aside) {
        border-bottom: 1px solid $card-border-color;
        &.card {
            .card-header {
                //Needed to make the more specific to get past the card styling
                .title.card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                    justify-content: flex-start;
                    @include header($product-refinement-header-value);
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &>.card {
        &:last-child {
            border-bottom: none;
        }
    }
    .values.content {
        font-size: 0;
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 0 calc(#{$collapsible-header-padding-left} - #{$card-body-padding-left});

        li {
            padding: 0;
            padding-bottom: $product-refinement-value-padding-bottom;
            display: block;
            max-width: 100%;
            &.col-sm-4 {
                width: 100%;
                flex-basis: 100%;
            }
        }
        button {
            border: none;
            background: none;
            padding: 0;
            text-align: left;
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
            }

            &:hover {
                i.fa {
                    &:before {
                        box-shadow: 0 0 0 1px $radiocheck-border-color;
                    }
                }
                .swatch-circle {
                    box-shadow: $color-swatch-hover-shadow;
                }
            }
            span {
                font-size: $product-refinement-font-size;
                line-height: calc( #{$product-refinement-font-size} + #{$radiocheck-vertical-spacing});
                text-transform: capitalize;
                display: inline-block;
                vertical-align: middle;
                hyphens: auto;

                &:not(.color-value) {
                    margin-right: $product-refinement-swatch-spacing;
                }
                &.unselectable.swatch-circle:hover {
                    box-shadow: none;
                }
                &.non-color-swatch-value {
                    @include swatch-non-color($product-refinement-non-color-swatch-min-width);
                    text-align: center;
                    margin-right: $product-refinement-non-color-swatch-spacing;
                }
            }
            i {
                &.fa {
                    &:before {
                        position: relative;
                        vertical-align: middle;
                        margin-right: $product-refinement-swatch-spacing;
                        transition: box-shadow $transition-speed ease-out;
                        display: flex;
                    }
                    &:after {
                        display: block;
                    }
                }
                &.fa-circle-o {
                    &:before {
                        @include radio-icon-background;
                    }
                }
                &.fa-check-circle {
                    &:before {
                        @include radio-icon-checked-background;
                    }
                    &:after {
                        @include radio-icon-checked;
                        position: relative;
                        z-index: 1;
                        margin-left: calc((#{$radiocheck-button-size} - (#{$radiocheck-button-size} - #{$radiobutton-selected-inset-amount})) / 2);
                        margin-top: calc( ((#{$radiocheck-button-size} - #{$radiobutton-selected-inset-amount}) + (#{$radiobutton-selected-inset-amount} / 2)) * -1);
                        top: unset;
                        left: auto;
                    }
                }
                &.fa-square-o {
                    &:before {
                        @include checkbox-icon-background;
                    }
                }
                &.fa-check-square {
                    &:before {
                        @include checkbox-icon-checked-background;
                    }
                    &:after {
                        @include checkbox-icon-checked;
                        position: relative;
                        z-index: 1;
                        margin-left: 0;
                        margin-top: calc( #{$radiocheck-button-size} * -1);
                        left: auto;
                    }
                }
            }
        }
        .swatches-vertical {
            @include refinement-display('vertical');
        }
        .swatches-horizontal {
            @include refinement-display('horizontal');
        }
        .swatches-grid {
            @include refinement-display('grid');
        }
    }
    .refinement-category {
        .values.content {
            flex-direction: column;
        }
        .values {
            li {
                display: block;
            }
            .values {
                margin-top: $radiocheck-vertical-spacing;
                li {
                    padding-top: $product-refinement-value-padding-bottom;
                    padding-bottom: 0;
                }
            }
        }
    }

    .swatch-color-text {
        max-width: calc(100% - #{$product-refinement-swatch-size} - #{$product-refinement-swatch-spacing});
        min-width: 0;
        padding-left: $product-refinement-swatch-spacing;
    }
}

//Horizontal Refinements - Desktop ONLY
@include media-breakpoint-up(lg) {
    .refinement-bar-horizontal {
        .horizontal-filter-btn.custom-select {
            width: auto;
        }
    }

    .refinement-bar-horizontal {
        .desktop-search-refinements {
            .refinements.horizontal-multipledropdowns-filter {
                .refinements-container {
                    .refinements-outer-grid {
                        display: grid;
                        grid-template-columns: 8fr 1fr;
                        grid-gap: $spacer;

                        .refinements-inner-grid {
                            display: flex;
                            flex-wrap: wrap;
                            grid-gap: $spacer;

                            .dropdown {
                                max-width: max-content;
                            }
                        }
                    }
                }
            }
        }
    }

    //Multiple Dropdowns
    .horizontal-multipledropdowns-filter-refinement.card.aside {
        height: $product-refinement-horizontal-multipledropdowns-height;
        border-top: $product-refinement-horizontal-multipledropdowns-btn-border-top;
        border-bottom: $product-refinement-horizontal-multipledropdowns-btn-border-bottom;
        border-left: $product-refinement-horizontal-multipledropdowns-btn-border-left;
        border-right: $product-refinement-horizontal-multipledropdowns-btn-border-right;
        min-width: $product-refinement-horizontal-multipledropdowns-min-width;
        //margin-right: $product-refinement-horizontal-multipledropdowns-margin-right;
        //margin-bottom: $product-refinement-horizontal-multipledropdowns-margin-bottom;
        border-radius: $product-filter-border-radius;

        &.refinement-category .values.content {
            margin-left: 0;
            margin-top: 0;

            &.values li {
                padding-top: 0;
            }
        }
    }

    .refinements .horizontal-multipledropdowns-filter-dropdown-menu .refinement-category .values.content button {
        padding-bottom: $product-refinement-horizontal-multipledropdowns-dropdown-menu-btn-padding-bottom;
    }

    .horizontal-multipledropdowns-filter-dropdown-menu {
        min-width: $product-refinement-horizontal-multipledropdowns-dropdown-menu-min-width;
        margin-top: $product-refinement-horizontal-multipledropdowns-dropdown-menu-margin-top;
        padding: $product-refinement-horizontal-dropdown-menu-padding;
        box-shadow: 0px 0px 1px 0px $border-color;

        .card-body {
            min-height: 0;
        }

        .values.content {
            margin-left: 0;
            margin-top: 0;
            min-height: 0;
            //max-width: $product-refinement-horizontal-dropdown-menu-max-width;
        }
        .card:not(#category) .values.content {
            max-height: 60vh;
            display: grid;
            grid-auto-flow: column;
            grid-template-rows: repeat(auto-fit, calc(#{$product-refinement-font-size} + #{$product-refinement-value-padding-bottom}));
            width: auto;

            .has-sub-categories {
                grid-row-start: 1;
                grid-row-end: var(--grid-end-row, -1);
            }

            li {
                width: max-content;
                padding-right: calc(#{$product-refinement-value-padding-bottom});

                button + ul {
                    margin-top: calc(#{$product-refinement-horizontal-multipledropdowns-dropdown-menu-btn-padding-bottom} * -1);
                    padding-bottom: $product-refinement-horizontal-multipledropdowns-dropdown-menu-btn-padding-bottom;
                }
            }
        }

        &.show {
            position: absolute;
            transform: translate3d(0px, calc(#{$button-line-height} + #{$input-padding-top} + #{$input-padding-bottom}), 0px) !important; //Bootstrap tries to calculate this based on page scroll and sometimes makes this dropdown jump to the top of the page
            top: 0px;
            left: 0px;
            will-change: transform;
            display: block;
            z-index: $z-index-refinement-drawer;
        }
    }

    .horizontal-multipledropdowns-filter-btn {
        border: none;
        padding-top: $product-refinement-horizontal-multipledropdowns-btn-padding-top;
        padding-bottom: $product-refinement-horizontal-multipledropdowns-btn-padding-bottom;
        padding-left: $product-refinement-horizontal-multipledropdowns-btn-padding-left;
        padding-right: $product-refinement-horizontal-multipledropdowns-btn-padding-right;

        &:after {
            float: right;
        }

        > span {
            padding-right: $product-refinement-horizontal-multipledropdowns-btn-padding-right;
        }

    }

    // One Dropdown
    .refinement-bar-horizontal-one-dropdown {
        .desktop-search-refinements {
            position: relative;

            .refinements {
                &:not(.sticky-filters) {
                    .refinements-container {
                        padding: 0;
                    }
                }
                .collapse-filters-wrapper {
                    display: none;

                    .collapse-filters {
                        border-top: $product-refinement-horizontal-onedropdown-border-top;
                        border-bottom: $product-refinement-horizontal-onedropdown-border-bottom;
                        border-right: $product-refinement-horizontal-onedropdown-border-right;
                        border-left: $product-refinement-horizontal-onedropdown-border-left;
                        margin-top: $product-refinement-horizontal-onedropdown-margin-top;
                    }

                    .collapse-one-dropdown-filter {
                        display: flex;
                        border-top: $product-refinement-horizontal-onedropdown-border-top;
                        border-bottom: $product-refinement-horizontal-onedropdown-border-bottom;
                        border-right: $product-refinement-horizontal-onedropdown-border-right;
                        border-left: $product-refinement-horizontal-onedropdown-border-left;
                        max-width: $product-refinement-horizontal-onedropdown-max-width;
                        margin-top: 15px;

                        .horizontal-onedropdown-filter-refinement {
                            width: 33%;
                            padding-top: $product-refinement-horizontal-onedropdown-refinement-padding-top;
                            padding-bottom: $product-refinement-horizontal-onedropdown-refinement-padding-bottom;
                            border: none;
                        }

                        .close-one-refinements-dropdown {
                            @include icon($icon-close);
                            display: flex;
                        }
                    }
                }

                &.sticky-filters .horizontal-onedropdown-sortBy {
                    position: relative;
                }
            }
        }
    }

    .horizontal-onedropdown-filter-btn.title {
        width: $product-refinement-horizontal-onedropdown-btn-width;
        background-color: $product-refinement-horizontal-onedropdown-btn-background-color;
        padding-right: $product-refinement-horizontal-onedropdown-btn-padding-right;
        padding-left: $product-refinement-horizontal-onedropdown-btn-padding-left;
        padding-top: $product-refinement-horizontal-onedropdown-btn-padding-top;
        padding-bottom: $product-refinement-horizontal-onedropdown-btn-padding-bottom;
        border-top: $product-refinement-horizontal-onedropdown-btn-border-top;
        border-bottom: $product-refinement-horizontal-onedropdown-btn-border-bottom;
        border-right: $product-refinement-horizontal-onedropdown-btn-border-right;
        border-left: $product-refinement-horizontal-onedropdown-btn-border-left;
        text-align: left;

    }

    .collapse-filters-wrapper .horizontal-onedropdown-filter-btn.title::after,
    .collapse-filters-wrapper.active .horizontal-onedropdown-filter-btn.title::after {
        content: '';
        display: none;
    }

    .horizontal-onedropdown-filter select[name="sort-order"] {
        position: absolute;
        right: 0;
        width: $product-refinement-horizontal-onedropdown-sortby-btn-width;
    }

    .horizontal-onedropdown-filter-group {
        width: 100%;
        .horizontal-onedropdown-filter-btn {
            background-image: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .horizontal-onedropdown-filter-btn-arrow {
                background-image: url(../images/icons/icon-arrow-down.svg); // this svg was generated from our icomoon font library
                background-size: $select-icon-size;
                width: $product-refinement-horizontal-onedropdown-filterby-btn-width;
                height: $product-refinement-horizontal-onedropdown-filterby-btn-height;
            }
        }

        &.active .horizontal-onedropdown-filter-btn-arrow {
            transform: scaleY(-1);
        }
    }
}

.horizontal-filter-refinements-bar {
    padding-top: $product-refinement-horizontal-padding-top;
    padding-bottom: $product-refinement-horizontal-padding-bottom;
    .refinements-container {
        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        select[name="sort-order"] {
            @include media-breakpoint-up(lg) {
                font-size: $button-font-size;
            }
        }
        > div[class*="flex"] {
            align-items: center;
        }
    }

    &.sticky-filters {
        .refinements-container {
            @include media-breakpoint-up(xl) {
                padding: 0 $spacer-xl;
            }
        }
    }
}

.sticky-filters {
    position: fixed;
    left: $product-refinement-horizontal-onedropdown-sticky-bar-left;
    z-index: $product-refinement-horizontal-onedropdown-sticky-bar-zindex;
    background: $product-refinement-horizontal-onedropdown-sticky-bar-background-color;
    width: 100%;
    border-bottom: $product-refinement-horizontal-onedropdown-sticky-bar-border-bottom;
    padding-left: $product-refinement-horizontal-onedropdown-sticky-bar-padding;
    padding-right: $product-refinement-horizontal-onedropdown-sticky-bar-padding;

    // behaviors to account for show/hide of enhanced sticky header
    .sticky-header & {
        transition: transform $header-transition-speed ease-out;
    }
    .sticky-header.scroll-direction-up & {
        transform: translateY(var(--header-height));
        transition: transform $header-transition-speed ease-out 50ms;
    }
}
