@import "~core/account/account";

.linc-optin-widget {
    margin-bottom: $spacer;
}

// Dashboard pages
.account-page {
    // light variant spinner on card page
    .payment-form {
        @include inverse-internal-spinner;
    }
    .card {
        p {
            font-size: $default-text-font-size;
        }
    }
    [class*="back-to-account-link"] {
        white-space: nowrap;
        @include button(tertiary);
    }
}

//SMS order modal
.Dialog {
    .customizable {
        > * {
            font-family: $body-font;
        }
        .dialog_close_button {
            @include icon($icon-close);
            svg {
                display: none;
            }
        }
        .SmsView {
            .button_container {
                .cancel_button {
                    @include button(secondary);
                }
                .submit_button {
                    @include button(primary);
                }
            }
        }
    }
}

.order-card {
    $step-line-height: 4px;
    .stepper {
        position: relative;
        width: 100%;
        margin: $spacer 0px;
        height: $step-line-height;

        .line {
            width: 100%;
            height: $step-line-height;
            background-color: $light-gray;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: $step-line-height;
            &:before {
                content: '';
                background-color: $brand-primary;
                position: absolute;
                left: 0;
                height: $step-line-height;
                width: 0%;
                border-radius: $step-line-height;
            }
        }

        .step {
            color: $white;
            font-size: calc(#{$spacer} - (#{$spacer} / 4));
            position: absolute;
            left: calc(#{$spacer} / 2);
            width: 0;
            height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 1px;

            &:before{
                z-index:1;
            }
            &:after {
                content: '';
                position: absolute;
                width: $spacer;
                height: $spacer;
                background-color: $brand-primary;
                border-radius: 50%;
            }
        }
        &.step-0 {
            .step { display: none; }
        }
        &.step-2 {
            .line:before { width: 50%; }
            .step { left: 50%;}
        }
        &.step-3 {
            .line:before { width: 100%; }
            .step { left: 100%;}
        }
    }

    .order-images {
        img {
            max-width: calc(100% / 4);
            padding-right: $spacer;
        }
    }
}
