.product-detail,
.quick-view-dialog {
    .price {
        color: $pdp-price-color;
        .strike-through {
            font-size: $product-strike-through-price-font-size;
            text-decoration: line-through;
            color: $product-strike-through-price-color;
        }
        del {
            all: unset;

            ~ .sales {
                float: left;
                margin-right: $pdp-price-margin-right;
            }
        }
        .tiered {
            color: $pdp-price-color;
            table {
                .table-header {
                    display: none;
                }
            }
            td,
            span.price {
                font-size: paragraph-font-size(small);
                font-weight: bold;
            }
            td {
                padding: $spacer/2;
                &.quantity {
                    font-weight: normal;
                    text-align: right;
                }
                &.value {
                    text-align: left;
                }
            }
            .table-header {
                padding: $spacer/2;
            }
            .column-header {
                padding: $spacer/2;
                font-weight: normal;
            }
            .column-header.quantity {
                text-align: right;
            }
        }
        .percent-off {
            font-size: $product-percent-off-price-font-size;
            text-decoration: none;
            color: $product-percent-off-price-color;
            padding-left: $spacer-sm;
        }
    }
}
