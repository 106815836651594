@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&family=Work+Sans:wght@400;600&display=swap');
// Locally stored fonts (must be placed in static/fonts folder)
// Change this one variable, the rest should be handled in client core
// For italic fonts set the object key to 'normal'

$local-fonts: (
    "Syne" : (
        700 : "Syne-Bold"
    )
);

@import "~client_core/utilities/fonts";