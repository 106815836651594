@import "~core/components/wishlist";
@import "wishlist/helpers";
@import "wishlist/detail";
@import "wishlist/overlay";
@import "wishlist/card";
@import "wishlist/toast";

.list-group-item,
.product-list-enhancements-card-list-header-link {
    overflow-wrap: anywhere;
    margin-right: $spacer;
}

.product-list-enhancements-modal-show-lists-toggle-lists {
    .toggle {
        overflow-wrap: anywhere;
    }
    .move {
        word-break: break-all;
    }
}