@import "~core/components/headerSearch";

.site-search {
    form {
        height: 100%;
    }
    .reset-button {
        background-color: transparent;
        &.d-sm-block {
            + button.fa-search {
                opacity: 0; //hide the search icon so the reset-button doesn't need a background color to hide it
            }
        }
    }
}
