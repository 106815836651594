@import "~core/components/common";


.error-page {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    min-height: 50vh;
    text-align: center;
    padding: #{$spacer * 2};
    @include media-breakpoint-up(md) {
        padding: #{$spacer-xl * 2};
    }

    > * {
        width: 100%;
        margin: 0 auto;
        padding: 5vw;
        @include media-breakpoint-up(md) {
            padding: 0 5vw;
        }
    }

    .page-title {
        @include header(4);
        margin: 0;
        padding-top: $spacer-xl;
        display: flex;
        flex-flow: column wrap;
        align-items: center;

        @include icon( $icon-alert, before, $h2-font-size, inherit);
        &:before {
            padding-bottom: $spacer-xl;
        }
    }

    .error-message {
        @include header(3);
    }

    pre code {
        white-space: normal;
    }
}