.product-detail-spacer {
    padding-top: $pdp-product-detail-top-spacer;
}

.product-detail {
    margin-bottom: $spacer;

    .breadcrumb-and-wishlist {
        margin-bottom: $pdp-breadcrumb-wishlist-line-margin-bottom;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .product-list-enhancements-toggle-product {
            position: relative;
            top: unset;
            bottom: unset;
            left: unset;
            right: unset;
        }
    }

    .primary-images {
        .primary-images-main {
            .slider {
                .slide {
                    border-radius: $pdp-product-slider-image-border-radius; //this will also apply to the presentation zoom-image
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    img {
                        border-radius: $pdp-product-slider-image-border-radius; //this will apply to main pdp image
                        position: relative;
                        z-index: 1;
                        padding-right: 1px; //edge getting cut off since slide container is 1px taller than wide
                        max-height: calc(100vh - var(--full-header-height, #{$header-height}) - #{$pdp-product-detail-top-spacer});
                    }

                    .background-image {
                        border-radius: calc( #{$pdp-product-slider-image-border-radius} + 1px); //offset for the extra padding
                        @include absolute-cover();
                        z-index: 0;
                        padding: 1px; //pull the image edges in a bit to ensure it doesn't peak out on images that have backgrounds
                        padding-right: 2px;
                    }

                }
            }
        }
        .slider-container {
            margin-bottom: 0;
            &.slider-thumbnails {
                .slider {
                    .slide {
                        border-radius: $slider-thumbnail-image-border-radius;
                        img {
                            border-radius: $slider-thumbnail-image-border-radius; //this will apply to main pdp thumbnails
                        }
                        &.tns-nav-active {
                            border-radius: $slider-thumbnail-active-border-border-radius;
                        }
                    }
                }
            }
        }

        .product-list-enhancements-toggle-product {
            display: none; // hiding after each attributeSelect
        }

        .primary-images-main {
            .badge-product-container {
                display: none;
            }

            &.product-has-thumbnails {
                margin-bottom: $slider-thumbnail-spacing;
            }

            .slide.no-zoom {
                cursor: zoom-in;
            }
            .slider {
                div.slide {
                    &:has(.page-designer),
                    &.pdp-gallery-asset {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .page-designer,
                        .page-designer [class*="experience"] {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-up(md) {
            &:not(.image-display-grid),
            &.image-display-grid + .product-data {
                &>.row {
                    position: $pdp-primary-images-position-type;
                    top: $pdp-primary-images-top-value;
                    z-index: $pdp-primary-images-z-index;
                    margin-left: 0;
                    margin-right: 0;
                    .quick-view-dialog & {
                        top: 0px;
                    }
                }
            }
            .primary-images-thumbnails {
                flex-basis: $slider-thumbnail-width-lg;
                max-width: $slider-thumbnail-width-lg;
                padding-left: 0;
                padding-right: 0;
                min-width: $slider-thumbnail-width-lg;
                height: calc( 100% - #{$slider-thumbnail-width-lg} -  #{$slider-thumbnail-width-lg / 2} - #{$slider-thumbnail-spacing * 2}); // 100% minus (a full image plus half and image plus two spacers between images)
                position: absolute;
                margin-left: calc(#{$slider-thumbnail-width-lg} / 2);
                margin-top: calc(#{$slider-thumbnail-width-lg} / 2);
                display: flex;
                align-items: $slider-thumbnail-flex-alignment;
                z-index: 1;

                 .pdp-video-assets {
                    top: calc(var(--thumbnail-product-slider-max-height-lg) + #{$slider-thumbnail-spacing});
                    position: absolute;
                    border-top: 1px solid $brand-secondary;
                    padding-top: #{$slider-thumbnail-spacing};
                    margin-bottom: #{$slider-thumbnail-spacing};

                    .modal & {
                        border-width: 0px;
                        padding-top: 0px;
                    }

                    img{
                        border-radius: $slider-thumbnail-image-border-radius;
                    }
                }
                // if the thumbnail slider control button is showing move the videos down by one more thumbnail width
                .d-md-block + .pdp-video-assets {
                    top: calc(var(--thumbnail-product-slider-max-height-lg) + #{$slider-thumbnail-width-lg} + #{$slider-thumbnail-spacing});
                }
                .video-asset-links {
                    display: none; // hide these in the thumbnail slider, photoswipe will create a slider from these
                }
                .slider-container:has(.slider-thumbnail-controls.d-md-block) {
                    .pdp-video-assets {
                        top: calc(var(--thumbnail-product-slider-max-height-lg) + #{$slider-thumbnail-width-lg} + #{$slider-thumbnail-spacing});
                    }
                }
            }
            .primary-images-main {
                padding-left: 0;
                padding-right: 0;
                &.product-has-thumbnails {
                    flex-basis: 100%; //calc(100% - #{$slider-thumbnail-width-lg} - #{$slider-thumbnail-spacing});
                    max-width: 100%; //calc(100% - #{$slider-thumbnail-width-lg} - #{$slider-thumbnail-spacing});
                    margin-left: 0; //$slider-thumbnail-spacing;
                    margin-bottom: 0;
                }
            }
        }

        // override to provide extra space for product attributes without copying over bundleDetails and setDetails templates to core
        @include media-breakpoint-only(sm) {
            &+.col-sm-6 {
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        .image-grid-outer {
            .quick-view-dialog & {
                overflow: hidden;
                max-height: calc(min((#{$modal-lg-max-width} - (#{$grid-quickview-padding} * 2 + #{$grid-image-spacing})), 75vh));
                margin: 0 $grid-quickview-padding;
                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            .image-grid-inner {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-rows: auto;
                grid-column-gap: $grid-image-spacing;
                grid-row-gap: $grid-image-spacing;

                .quick-view-dialog & {
                    max-height: calc(min((#{$modal-lg-max-width} - (#{$grid-quickview-padding} * 2 + #{$grid-image-spacing})), 75vh));
                    overflow: scroll;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                        width: 0px;
                    }
                }

                & > .slide:first-child {
                    //grid-area: 1 / 1 / 3 / 3; SAS-1098 making first image same size as the rest of the grid
                }

                .slide {
                    @include aspect-ratio($aspect-ratio-square);
                    img {
                        @include aspect-ratio($aspect-ratio-square);
                        object-fit: cover;
                        object-position: center center;
                        border: 1px solid $brand-secondary;
                    }
                }
                .slide,
                .slide img {
                    max-height: calc(100vh - var(--full-header-height, #{$header-height}) - #{$pdp-product-detail-top-spacer});
                    margin: 0 auto;
                }
            }
        }

        .video-asset-links-trigger {
            cursor: pointer;
        }
    }

    [class*="product-data"] {
        .badge-product-container,
        .badge-product-container.badge-image-over {
            margin-bottom: $pdp-badge-above-title-margin-bottom;
            flex-direction: $pdp-badge-above-title-flex-direction;
            position: relative;
            align-items: center;

            .badge-product {
                padding: $pdp-badge-above-title-padding;
                @include banner($pdp-badge-above-title-banner-value);
                background-color: $pdp-badge-above-title-background-color;
                border-radius: $pdp-badge-above-title-border-radius;
                color: $dark-gray;
            }
        }
    }

    .row > .product-data {
        @include media-breakpoint-up(md) {
            padding-left: $pdp-right-column-spacing-md;
        }
        @include media-breakpoint-up(lg) {
            padding-left: $pdp-right-column-spacing-lg;
        }
        @include media-breakpoint-up(xl) {
            padding-left: $pdp-right-column-spacing-xl;
        }
    }
    .product-name {
        @include header($pdp-name-header-value);
        color: $pdp-name-header-color;
        a {
            text-decoration: none;
        }
    }
    .attributes {
        .non-input-label {
            font-size: $pdp-attribute-label-font-size;
            color: $pdp-attribute-non-input-label-color;
            > [class*="value"] {
                color: $base-font-color;
                margin-left: calc(#{$spacer} / 2);
            }
        }
        .attribute {
            button {
                cursor: default;
                &[data-url]:not([data-url="null"]) {
                    span {
                        cursor: pointer;
                        &.unavailable span {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
        .availability-msg  {
            font-size: $pdp-attribute-label-font-size;
        }
    }
    .attribute,
    .product-option {
        margin-top: $pdp-attribute-vertical-spacing;
        @include media-breakpoint-up(md) {
            margin-top: $pdp-attribute-vertical-spacing-md;
        }
        @include media-breakpoint-up(lg) {
            margin-top: $pdp-attribute-vertical-spacing-lg;
        }
        label {
            display: block;
        }
    }
    .qty-cart-container {
        margin-top: $spacer;
        margin-bottom: $spacer;
        align-items: center;
        justify-content: space-between;
        .quantity {
            flex: 0 0 25%;
        }
        .prices-add-to-cart-actions {
            flex: 1 1 75%;
        }
    }
    .quantity {
        display: flex;
        align-items: center;
        margin-bottom: $spacer;

        label {
            @include label-styles;
            margin-right: 10px;
            &:after {
                content: ':';
            }
        }
    }
    .quantity-stepper,
    .quantity-select {
        @include media-breakpoint-up(sm) {
            width: calc(100% - 10px);
        }
    }
    .simple-quantity {
        margin-top: $spacer;
    }
    .availability-container {
        display: flex;
        align-items: center;
        margin-bottom: $form-label-margin-bottom;
        margin-top: $pdp-attribute-vertical-spacing;
        @include media-breakpoint-up(md) {
            margin-top: $pdp-attribute-vertical-spacing-md;
        }
        @include media-breakpoint-up(lg) {
            margin-top: $pdp-attribute-vertical-spacing-lg;
        }
    }
    .swatch-circle {
        @include swatch($pdp-color-swatch-size, $white);
        margin: calc(#{$pdp-color-swatch-spacing} / 2) $pdp-color-swatch-spacing calc(#{$pdp-color-swatch-spacing} / 2) 0;
    }
    .swatch-thumbnail {
        @include swatch($pdp-color-swatch-thumbnail-size, $white, 'thumbnail');
        margin: calc(#{$pdp-color-swatch-thumbnail-spacing} / 2) $pdp-color-swatch-thumbnail-spacing calc(#{$pdp-color-swatch-thumbnail-spacing} / 2) 0;
    }
    [disabled] .swatch,
    .swatch.unselectable {
        cursor: not-allowed;
        &.color-value.selected::after {
            background-color: $gray;
        }
    }
    label.color ~ a:hover {
        text-decoration: none; // Removes weird dash after the swatch circle on hover.
    }
    .non-color-attribute-swatches {
        @include clearfix();
        .swatch-tile-container {
            float: left;
            display: block;
            margin: calc(#{$pdp-non-color-swatch-spacing} / 2) $pdp-non-color-swatch-spacing calc(#{$pdp-non-color-swatch-spacing} / 2) 0;
            .swatch {
                background: none;
                display: block;
                position: relative;
                padding: 0;
                border: none;
                .swatch-value {
                    @include swatch-non-color($pdp-non-color-swatch-min-width);
                }
                .description {
                    display: block;
                    text-align: center;
                    font-size: $non-color-swatch-description-font-size;
                    color: $non-color-swatch-description-font-color;
                    font-weight: normal;
                }
            }
        }
    }
    .social-container {
        padding-top: $pdp-attribute-vertical-spacing;
        .social-icons {
            align-items: center;
            li:not(:last-child) {
                margin-right: $pdp-social-icon-spacing;
            }
            .share-icons {
                font-size: $pdp-social-icon-size;
                color: $pdp-social-icon-color;
                &:hover {
                    color: $pdp-social-icon-color-hover;
                }
            }
        }
    }
    .product-number-rating {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: $spacer;
        clear: left;
    }
    .product-number {
        color: $pdp-product-number-color;
        font-size: $pdp-product-number-font-size;
    }
    .ratings {
        margin-left: $pdp-rating-star-margin-left;
        margin-bottom: $pdp-rating-star-margin-bottom;

        .fa-star {
            @include icon($icon-star, before, $pdp-rating-star-size, $product-rating-star-color);
        }
        .fa-star-half-o {
            @include icon($icon-star-half, before, $pdp-rating-star-size, $product-rating-star-color);
        }
        .fa-star-o {
            @include icon($icon-star-empty, before, $pdp-rating-star-size, $product-rating-star-color);
        }

        .bv-ratings-count, .bv-rating-stars-rating {
            margin-left: $spacer-sm;
        }
    }
    .promotions {
        margin-top: $spacer;
        .promotion {
            color: $pdp-promo-color;
        }
    }
    .prices {
        margin-top: $spacer;
        margin-bottom: $spacer;
        @include media-breakpoint-up(sm) {
            margin-bottom: calc(#{$spacer} / 2);
        }
        .price {
            font-size: $pdp-price-font-size;
            font-family: $pdp-price-font;
        }
    }
    .size-chart {
        font-size: $paragraph-font-size-small;
        h2 {
            @include header(4);
        }
        .sizinginformation {
            .nav-tabs .nav-link.active {
                background-color: $page-background-color;
            }
            .tab-content {
                border: $tab-border-width solid $tab-border-color;
                margin-bottom: $spacer;
                .sizechart {
                    margin-top: -$tab-border-width;
                }
                .sizecharttable {
                    margin-bottom: 0;
                }
            }
        }
    }
    .attribute .size-chart {
        position: absolute;
        right: $spacer;
    }

    .airline-fit-carry-on {
        display: flex;
        flex-flow: column wrap;

        .size-fit-links {
            display: inline-flex;
            align-items: center;
            padding-top: $spacer;

            i {
                font-size: $pdp-airline-carry-on-icon-size;
            }
        }
    }

    .color-attribute {
        display: block;
        float: left;
        &.swatch-hide {
            display: none;
        }
    }
    label,
    .non-input-label {
        @include label-styles;
        display: block;
    }

    // product sets
    .set-item,
    &.set-item {
        padding: 0;
        .attribute.quantity {
            align-self: flex-end;
            margin-bottom: calc(#{$pdp-non-color-swatch-spacing} / 2);
        }
        .size-chart {
            margin-top: calc(#{$pdp-attribute-vertical-spacing} - #{$pdp-non-color-swatch-spacing/2});
            @include media-breakpoint-up(md) {
                margin-top: calc(#{$pdp-attribute-vertical-spacing-md} - #{$pdp-non-color-swatch-spacing/2});
            }
            @include media-breakpoint-up(lg) {
                margin-top: calc(#{$pdp-attribute-vertical-spacing-lg} - #{$pdp-non-color-swatch-spacing/2});
            }
        }
    }

    .set-items {
        padding-top: $product-set-list-padding-top;

        @include media-breakpoint-up(lg) {
           &.product-has-thumbnails {
                margin-left: $product-set-with-thumbnails-content-margin-left;
            }
        }

        .product-set-item-main-image {
            margin-bottom: $spacer;
        }
    }

    .product-set-contains {
        @include header($product-set-contains-header-value);
        color: $pdp-name-header-color;
        border-top: $product-set-item-list-border-color solid $product-set-contains-border-width;
        border-bottom: $product-set-item-list-border-color solid $product-set-contains-border-width;
        margin: $product-set-contains-margin;
        padding: $product-set-contains-padding;
        background-color: $product-set-contains-background;
        + .product-set-item-detail {
            border-top: 0;
            margin-top: 0;
        }
    }

    .product-set-item-detail {
        border-top: $product-set-item-list-border-color solid $product-set-item-list-border-width;
        margin-top: $product-set-item-list-margin-top;
        padding-top: $product-set-item-list-padding-top;

        .set-item-product-name {
            @include header($product-set-item-list-product-name-header-value);
            color: $product-set-item-list-product-name-header-color;
            a {
                text-decoration: $product-set-item-list-product-name-text-decoration;
            }
        }

        .set-item-headline-and-description {
            display: $product-set-item-list-headline-and-description-display;
        }

        .set-item-price {
            .price {
                @include header($product-set-item-list-product-price-header-value);
                color: $product-set-item-list-product-price-color;
            }
        }

        .mini-attributes {
            .attribute,
            .availability-container,
            .qty-cart-container {
                margin-top: $product-set-item-list-attr-margin-top;
            }

            .qty-cart-container {
                margin-bottom: 0;
            }

            .attribute .size-chart {
                @include media-breakpoint-up(xl) {
                    padding-right: 8px; // fix for row padding change
                }
            }
        }
    }

    // product bundles
    .product-bundle {
        .addtocart-sticky-bar {
            .add-to-cart.btn.btn-primary {
                width: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                padding: calc(min(10px, 2vh)) 0px;
            }
        }

        .bundle-footer {
            .qty-cart-container {
                .quantity {
                    &.d-none {
                        + .prices-add-to-cart-actions {
                            // fix cart btn width when qty is hidden
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
    .bundle-items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(#{$product-bundle-item-min-width}, 1fr));
        grid-gap: $product-bundle-item-grid-gap;

        .bundle-item {
            padding: $spacer;
            border-top: 1px solid $product-bundle-item-top-border-color;

            .zoomImg {
                display: none !important; // hiding the zoom for smaller product bundle items since its too large
            }

            .product-name {
                @include header($product-bundle-item-name-font-size);
            }

            // fixing alignment of product attributes
            .product-number-rating {
                flex-direction: column;
                .ratings {
                    margin-left: 0;
                }
            }

            .product-number-rating,
            .ratings,
            .availability-container,
            .attribute,
            .product-option {
                margin-top: $product-bundle-item-pdp-attribute-vertical-spacing;
                @include media-breakpoint-up(md) {
                    margin-top: $product-bundle-item-pdp-attribute-vertical-spacing-md;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: $product-bundle-item-pdp-attribute-vertical-spacing-lg;
                }
            }

            .product-option {
                padding-left: 0;
            }
        }
    }
}

.tabs {
    margin-top: $spacer;
    margin-bottom: $spacer;
    padding-top: $pdp-tabs-vertical-spacing;
    @include media-breakpoint-up(md) {
        padding-top: $pdp-tabs-vertical-spacing-md;
    }
    @include media-breakpoint-up(lg) {
        padding-top: $pdp-tabs-vertical-spacing-lg;
    }

    .nav-link {
        @include header($pdp-tabs-header-value);
    }

    &.product-has-thumbnails {
        @include media-breakpoint-up(lg) {
            padding-left: $pdp-product-has-thumbnails-content-padding-left;
        }
    }
}

.main-content-group {
    .main-content-item {
        margin-top: $spacer;
        margin-bottom: 0px;

        + .review {
            margin-bottom: $spacer;
        }

        .page-designer & {
            margin-top: 0px;
            margin-bottom: 0px;

            + .review {
                margin-bottom: 0px;
                .card {
                    border-bottom: 0px;
                }
            }
        }

        padding-top: $pdp-description-detail-vertical-spacing;
        @include media-breakpoint-up(md) {
            padding-top: $pdp-description-detail-vertical-spacing-md;
        }
        @include media-breakpoint-up(lg) {
            padding-top: $pdp-description-detail-vertical-spacing-lg;
        }
    }

    &.product-has-thumbnails {
        @include media-breakpoint-up(lg) {
            padding-left: $pdp-product-has-thumbnails-content-padding-left;
        }
    }
    .card {
        .card-header {
            .title {
                @include header($pdp-description-detail-header-value);
            }
        }
        .card-body {
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: $pdp-description-detail-vertical-spacing;
            @include media-breakpoint-up(md) {
                padding-left: $card-spacer-x;
                padding-right: $card-spacer-x;
                padding-bottom: $pdp-description-detail-vertical-spacing-md;
            }
            @include media-breakpoint-up(lg) {
                padding-bottom: $pdp-description-detail-vertical-spacing-lg;
            }
        }
    }
    .main-attributes {
        margin-bottom: $card-margin-bottom;
    }
    .product-attributes {
        .attribute-name {
            @include header($pdp-description-detail-header-value);
        }
    }
    // dividing lines when sections are collapsible
    .product-attributes + .collapsible-content.collapsible-sm.border-top,
    .collapsible-content.collapsible-sm.border-top {
        @include media-breakpoint-down(sm) {
            border-top: $pdp-description-detail-divider;
        }
    }
    .product-attributes + .collapsible-content.collapsible-xl.border-top,
    .collapsible-content.collapsible-xl.border-top {
        border-top: $pdp-description-detail-divider;
    }

    //Description Area
    .description-container {
        padding-left: 0px;

        .description {
            &-content {
                flex: 1 1 calc(100% * (#{$pdp-description-drawer-content-column-width} / 12));
                @include media-breakpoint-up(md) {
                    padding-right: $spacer-sm;
                    padding-left: $spacer-sm;

                    + .description-image {
                        padding-left: $pdp-description-drawer-column-padding;
                    }
                }
            }
            &-image {
                flex: 1 1 calc(100% * (#{$pdp-description-drawer-image-column-width} / 12));
                padding-bottom: $spacer;
                @include media-breakpoint-up(md) {
                    padding-bottom: 0px;
                }

                .photo-tile-container {
                    .photo-tile-figure {
                        margin-bottom: $spacer;
                    }
                    img {
                        border-radius: $pdp-description-drawer-image-border-radius;
                    }
                }
            }
        }
    }

    //Specification area
    .specification-container {
        overflow: hidden;
        padding-left: 0px;

        .specification-inner-container {
            margin-top: calc((#{$pdp-specification-drawer-specs-item-spacing-top-bottom} * -1) + 2px); //+2px for negative margin-top on tooltip icon
            flex: 1 1 calc(100% * (#{$pdp-specification-drawer-specs-column-width} / 12));
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(#{$pdp-specification-drawer-specs-item-min-width}, 1fr));
            grid-gap: $pdp-specification-drawer-specs-item-spacing-left-right;
            grid-row-gap: $pdp-specification-drawer-specs-item-spacing-top-bottom;

            @include media-breakpoint-up(md) {
                padding-right: $spacer;
                padding-left: $spacer-sm;

                + .airline-fit-carry-on {
                    padding-left: $pdp-description-drawer-column-padding;
                }
            }

            .product-specification {
                display: flex;
                align-items: flex-start;
                flex-flow: column wrap;
                padding-top: calc(#{$pdp-specification-drawer-specs-item-spacing-top-bottom} - #{$pdp-specification-drawer-specs-item-border-top-width}); //should be the same as margin-top of inner container minus the border width
                border-top: $pdp-specification-drawer-specs-item-border-top-width solid $pdp-specification-drawer-specs-item-border-top-color;

                &-name {
                    @include banner($pdp-specification-drawer-specs-name-banner-value);
                    color: $pdp-specification-drawer-specs-name-color;
                    margin-bottom: $pdp-specification-drawer-specs-name-margin-bottom;
                    display: flex;
                    gap: $pdp-specification-drawer-specs-item-spacing-left-right;

                    span.tooltip-icon {
                        &:before {
                            color: inherit;
                        }
                    }
                }

                &-value {
                    @include paragraph-font-size(normal);
                    font-weight: $pdp-specification-drawer-specs-value-font-weight;
                }
            }
        }

        .airline-fit-carry-on {
            flex: 1 1 calc(100% * (#{$pdp-specification-drawer-size-guide-buttons-column-width} / 12));
            margin-top: #{$pdp-specification-drawer-specs-item-spacing-top-bottom};
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }

            .size-fit-links {
                @include button(secondary, null, null, null, null);
                display: flex;
                align-items: center;
                width: fit-content;
                margin-bottom: $spacer * 1.5;
                padding: 0px; //fix for using an a in a button

                > * {
                    font-size: inherit;
                    text-decoration: none;
                    color:inherit;
                }

                a {
                    padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom 0; //fix for using an a in a button
                }
                i {
                    padding: 0 0 0 $button-secondary-padding-left; //fix for using an a in a button
                    font-size: $pdp-airline-carry-on-icon-size;
                }
            }
            &:not(:has(.carry-on-link)) {
                display: none;
            }
        }
        &.specification-container-set-items {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            gap: $spacer;
            max-width: 100%;

            .product-set-specifications-wrapper {
                .specification-inner-container {
                    + .airline-fit-carry-on {
                        display: none;
                    }
                }
            }
        }
    }
}

.prices-add-to-cart-actions {
    .add-to-cart {
        @include icon($pdp-add-to-cart-button-icon, before);
    }
    .js-paymentmethod-mutationobserver {
        &>*:not(.d-none):not(.js-paymentmethodwarning-affectedcontainer){
            margin-top: $spacer * 1.5;
        }
    }
}

.cart-and-ipay {
    text-align: center;

    // fix for bootstrap column padding making right edge of qty unclickable
    &.row:not(.no-gutters) {
        margin-left: 0;
        margin-right: 0;
        > .col-sm-12 {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .btn {
        width: 100%;
    }
    @include media-breakpoint-up(sm) {
        padding-bottom: 0;
    }
    .add-to-cart-global,
    .update-cart-product-global {
        @include icon($pdp-add-to-cart-button-icon, before);
    }
}
.quantity.d-none + .prices-add-to-cart-actions {
    .cart-and-ipay {
        padding-left:0;
        padding-right:0;
    }
}

.add-to-cart-messages {
    @include toast-message();
}

.add-to-basket-alert {
    @include toast-alert();
}


//** Sticky Add to Cart and Related Styles **//
.jumplink-anchor {
    position: absolute;
    top: -150px;
}
.addtocart-sticky-bar {
    pointer-events: none;
    display: block;
    position: fixed;
    top: 0;
    background: $pdp-sticky-addtocart-background;
    width: 100%;
    left: 0;
    z-index: $z-index-pdp-sticky-add-to-cart;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: calc(min(10px, 2vh));
    padding-bottom: calc(min(10px, 2vh));
    opacity: $pdp-sticky-addtocart-opacity;
    transform: translateY(-100%);
    transition: $pdp-sticky-addtocart-open-transition;
    overflow: hidden;
    box-shadow: $pdp-sticky-addtocart-shadow;
    .name,
    .links {
        margin: auto;
    }
    h1.product-name {
        @include header($pdp-sticky-addtocart-name-header-value);
        color: $pdp-name-header-color;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 50vw;
    }
    a.jumplink {
        margin: 0 15px;
        position: relative;
        z-index: 10;

        &.selected,
        &:hover {
            text-decoration: none;
        }
    }
    .sticky-attribute {
        float: left;
        &+.sticky-attribute {
            margin-left: $pdp-sticky-addtocart-attribute-spacing;
        }
    }
    .attribute {
        margin-top: 0;
        label,
        .non-input-label,
        .size-chart {
            display: none;
        }
        .swatch-circle{
            width: $pdp-sticky-addtocart-color-swatch-size;
            height: $pdp-sticky-addtocart-color-swatch-size;
            margin: calc(#{$pdp-sticky-addtocart-color-swatch-spacing} / 2) $pdp-sticky-addtocart-color-swatch-spacing calc(#{$pdp-sticky-addtocart-color-swatch-spacing} / 2) 0;
        }
        .swatch-thumbnail {
            width: $pdp-sticky-addtocart-color-swatch-thumbnail-size;
            height: $pdp-sticky-addtocart-color-swatch-thumbnail-size;
            margin: calc(#{$pdp-sticky-addtocart-color-swatch-thumbnail-spacing} / 2) $pdp-sticky-addtocart-color-swatch-thumbnail-spacing calc(#{$pdp-sticky-addtocart-color-swatch-thumbnail-spacing} / 2) 0;
        }
        .custom-select {
            font-size: $pdp-sticky-addtocart-attribute-select-font-size;
            line-height: $pdp-sticky-addtocart-attribute-select-line-height;
            padding: $pdp-sticky-addtocart-attribute-select-padding;
            height: $pdp-sticky-addtocart-attribute-select-height;
        }
    }
    .cart-and-ipay {
        text-align: right;
        padding-bottom: 0;
        .add-to-cart.btn.btn-primary {
            font-size: $pdp-sticky-addtocart-button-font-size;
            line-height: $pdp-sticky-addtocart-button-line-height;
            padding: $pdp-sticky-addtocart-button-padding;
            letter-spacing: $pdp-sticky-addtocart-button-letter-spacing;
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }

    > .container > .row {
        flex-wrap: nowrap;
        &> div {
            flex-grow: 1;
            flex-shrink: 1;
            max-width: unset;
            align-items: center;
            //Product Name
            &:nth-child(1) {
                flex-basis: clamp(200px, 25%, 100%);
            }
            //Product Attributes
            &:nth-child(2) {
                flex-basis: clamp(0px, 50%, 100%);
                justify-content: center;
            }
            //Add to Cart btn
            &:nth-child(3) {
                flex-basis: clamp(150px, 25%, 300px);
            }
        }
    }

    // behaviors to account for show/hide of enhanced sticky header
    .sticky-header & {
        transition: transform $header-transition-speed ease-out;
    }
    .sticky-header.scroll-direction-up .showstickybar & {
        transform: translateY(var(--header-height));
        transition: transform $header-transition-speed ease-out 50ms;
    }
}
.showstickybar {
    .addtocart-sticky-bar {
        pointer-events: auto;
        transform: translateY(0);
    }
}

.suggested-products-slider {
    margin-top: $spacer * 2;
    border-top: 1px solid map-get($border, secondary);
}

//Suggested Products Popover styling for mobile overriding
$popover-max-mobile-width: 300px;
.popover[id^="popover"] {
    @include media-breakpoint-down(sm) {
        width: $popover-max-mobile-width;
        left: calc((100vw - #{$popover-max-mobile-width}) / 2) !important;
        right: calc((100vw - #{$popover-max-mobile-width}) / 2) !important;
        transform: unset !important;
        position: fixed !important;
        top: 25vh !important;
    }
}


// BAZAARVOICE OVERRIDES
.page {
    [data-bv-show=rating_summary] {
        div {
            display: flex !important;
            align-items: center !important;
        }
    .bv_main_container {
        .bv_stars_component_container,
        .bv_main_container_row_flex {
            padding-right: $spacer !important;
        }
        .bv_avgRating_component_container,
        .bv_avgRating_component_container:hover {
            font-size: 14px !important;
            font-weight: 400 !important;
        }
        .bv_numReviews_text,
        .bv_numReviews_text:hover {
            color: map-get($content, secondary) !important;
            font-weight: 400 !important;
            font-size: 12px !important;
        }
        .bv_button_component_container {
            // display: inline !important;
        }
        .bv_button_buttonMinimalist,
        .bv_button_buttonMinimalist:hover {
            color: map-get($content, secondary) !important;
            font-weight: 400 !important;
            font-size: 12px !important;
            text-decoration: underline !important;
            &:hover {
                color: map-get($content, primary) !important;
            }
        }
    }
}}

// BazaarVoice PDP Review/Questions
[class*="collapsible"] .bv-review-container .value.content {display: block; padding: 0;}
.bv-review-container {
    .review-qa-tabs {
        margin: 0 0 #{$spacer * 3} 0;
        padding: 0;
        list-style: none;
        width: 100%;
        text-align: center;

        li {
            display: inline-block;
            margin: 0;
            padding: 0 $spacer;
            list-style: none;
            a {
                &.reviews-nav,
                &.questions-nav {
                    text-decoration: none;
                    cursor: pointer;
                    display: inline-block;
                    @include header(3);
                    &.active {
                        border-bottom: 1px map-get($border, primary) solid;
                    }
                    &:hover {
                        text-decoration: none;
                        border-bottom: 1px map-get($border, primary) solid;
                    }
                }
            }
        }
    }

    .reviews-tab,
    .questions-tab {
        visibility: hidden;
        display: none !important;
        height: 0;
        overflow: hidden;

        &.active {
            visibility: visible;
            display: block !important;
            height: auto;
            overflow: visible;
        }
    }
    .bv-cv2-cleanslate .bv-core-container-83 .bv-content-search .bv-masthead {
        .bv-content-product-stats .bv-content-product-stats-item .bv-content-data-count .bv-content-data-label,
        .bv-masthead-product .bv-rating-label {
            color: #283EB8 !important; //in design but not in defined colors
        }
    }
}

// BV inline ratings in pdp content drawers for "hosted" reviews
.product-detail {
    .product-data-header {
        .ratings {
            .bv_main_container {
                padding-top: $spacer-sm !important;
                .bv_stars_component_container {
                    svg {
                        padding-right: $product-tile-rating-star-padding !important
                    }
                }
            }
        }
    }
    .main-content-item, .review {
        .ratings {
            .plp-inline-rating { display: none;}
            .pdp-inline-bv-rating-drawer {
                margin-right: calc(#{$collapsible-header-padding-right} * -1); //move the reviews over to the edge of the card header

                .bv_main_container {
                    & > *:not(.bv_ratings_summary) {
                        display: none !important; // hide all the stuff thats not ratings stars or count
                    }

                    .bv_ratings_summary { // button that holds the stars, count, and rating
                        pointer-events: none;

                        > * {
                            order: 2;
                        }

                        .bv_numReviews_component_container {
                            order: 1;
                            width: 100% !important; // push all the order 2 stuff to the right

                            .bv_numReviews_text {
                                font-family: $header-font !important;
                                color: $base-font-color !important;
                                font-size: $default-text-font-size !important;
                            }
                        }
                    }

                    .bv_stars_component_container {
                        min-width: calc((#{$product-tile-rating-star-size} + #{$product-tile-rating-star-padding}) * 5) !important; // makes sure the stars arent' too small on mobile
                        svg {
                            padding-right: $product-tile-rating-star-padding !important
                        }
                    }

                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%; height: 100%;
                    top: 0; left: 0;
                    cursor: pointer;
                }
            }
        }
    }

    .set-items {
        input:checked+label:before {
            content: attr(data-button-text-in-set);
        }
        input+label:before {
            content: attr(data-button-text-default);
            position: relative;
            opacity: 1;
        }
    }
}

.set-banner-bar-wrapper {
    border-top: 1px solid map-get($border, secondary);
    bottom: 0;
    display: none;
    position: fixed;
    width: 100%;
    z-index: calc(#{$z-index-sticky-footer} + 1);
    background-color: $white;
    padding: $spacer 0;

    .set-banner-bar-slot-container {
        align-items: center;
        flex-flow: column wrap;
        @include media-breakpoint-up(md) {
            flex-flow: row nowrap;
            min-height: $plp-product-compare-image-max-height;
            justify-content: space-between;
        }
    }

    .product-slots {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
        }
        height: auto;
        justify-content: space-evenly;
        align-items: center;
        flex-grow: 1;
        transition: all $transition-speed ease-in-out;

        .selected-product {
            display: flex;
            transition: all $transition-speed ease-in-out;
            max-width: $plp-product-compare-image-max-height;

            &:not(:first-child) {
                margin-left: $spacer;
            }

            &:has([data-pid]) {
                display: flex;
            }

            &:has(.min-products-msg) {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: none;
            }

            @-moz-document url-prefix() {
                .alert {
                    min-width: max-content; //firefox fix
                    left: -100%; //firefox fix
                }
            }

            .remove-btn {
                @include delete-card-button;
                left: unset;
                right: unset;
                top: unset;
                bottom: unset;
                margin: 0;
            }
        }
    }

    .set-banner-messaging {
        display: flex;
        align-items: center;
        flex-flow: column wrap;
        justify-content: center;

        .pricing {
            display: flex;
            gap: $spacer;
        }

        .set-banner-pricing {
            display: flex;
            gap: $spacer;

            .messaging {
                color: $product-percent-off-price-color;
            }

            .strike-through {
                text-decoration: line-through;
            }

            .strike-through,
            .sales {
                &:before {
                    content: '$';
                }
            }

            .strike-through,
            .messaging {
                display: none;
            }

            &.discounted {
                .strike-through,
                .messaging {
                    &:not(:empty) {
                        display: block;
                    }
                }
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    .slot {
        background-color: transparent;
        width: auto;
        border-radius: $border-radius;
        display: flex;
        align-items: flex-start;
        min-height: $plp-product-compare-image-max-height;

        &:not([data-pid]) {
            border: 1px dashed #ccc;
            align-items: center;
        }

        img {
            align-self: center;
            border-radius: $border-radius 0 0 $border-radius;
            max-height: $plp-product-compare-image-max-height;
        }
    }
}
