.minicart {
    position: relative;
    .popover {
        top: calc(100% - 1px);
        left: auto;
        right: -1px;
        min-width: calc(100vw - (#{$spacer} * 2));
        @include media-breakpoint-up(md) {
            min-width: $minicart-size;
        }
        @include media-breakpoint-up(xl) {
            $minicart-augmented-size: calc(max(#{$minicart-size}, ((#{map-get($grid-breakpoints, xl)} / 12) * var(--product-data-column-span, 3))));
            width: $minicart-augmented-size;
            max-width: $minicart-augmented-size;
        }
        max-width: $minicart-size;
        min-height: 0px;
        max-height: 0px;
        margin-right: $spacer;
        box-shadow: none;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        opacity: 0;
        z-index: -1;
        overflow: hidden;
        transition: opacity $transition-speed ease-in-out, max-height calc(#{$transition-speed} * 2) ease-out;

        @include inverse-internal-spinner;

        &.show {
            opacity: 1;
            z-index: 0;
            overflow: auto;
            min-height: $minicart-min-height;
            max-height: calc(100vh - var(--full-header-height) - #{$spacer});

            div:last-child.cart {
                .minicart-label.item-added {
                    color: $white;
                    display: flex;
                    align-items: center;
                    $icon-added-checkmark-size: 22px;
                    $icon-added-checkmark-margin-right: calc(#{$spacer} / 2);
                    $icon-added-width: calc(#{$icon-added-checkmark-size * 2});
                    @include icon($icon-checkmark, before, $icon-added-checkmark-size, $success);
                    &:before {
                        opacity: 1;
                        width: $icon-added-width;
                    }
                    &:after {
                        opacity: 1;
                        content: attr(data-title-onadd);
                        margin-left: calc(#{$icon-added-width} + #{$icon-added-checkmark-margin-right});
                    }
                }
            }
        }
    }
    .close-minicart {
        color: $black;
        text-shadow: none;
        opacity: 1;
        line-height: $h3-line-height;
        margin-top: -2px; // offset to align with h3 minicart-label

        @include media-breakpoint-up(md) {
            line-height: $h3-md-line-height;
        }
        @include media-breakpoint-up(lg) {
            line-height: $h3-lg-line-height;
        }
    }
    .minicart-label {
        @include header(3);
        &.item-added {
            transition: color $transition-speed ease;
            &:before,
            &:after {
                content: '';
                position: absolute;
                background: white;
                opacity: 0;
            }
            &:before {
                width: 0px;
                transition: opacity $transition-speed ease-in-out calc(#{$transition-speed} * 3),
                            width $transition-speed ease-out $transition-speed;
            }
            &:after {
                left: 0;
                margin-left: 0px;
                color:$base-font-color;
                transition: opacity $transition-speed ease-in-out calc(#{$transition-speed} * 2),
                            margin-left calc(#{$transition-speed} * 2) cubic-bezier(0.68, -0.55, 0.265, 1.55) $transition-speed;
            }
        }
    }

    .cart {
        padding-top:calc(#{$spacer} / 2);
        background-color: $page-background-color;
        border-radius: $border-radius;
    }
    .remove-btn {
        @include delete-card-button;
        margin: 0px;
        left: 0;
        right: auto;
    }
    .product-summary {
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: $spacer;
        padding-top: $spacer;
        width: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .line-item-name {
            width: calc(100% - #{$product-card-remove-icon-size} - #{$spacer} / 2);
        }
    }
    .minicart-item {
        &+.minicart-item {
            margin-top: calc(#{$spacer} - #{$spacer} / 2);
            padding-top: $spacer;
            border-top: $product-card-item-border-width solid $product-card-border-color;
            border-radius: $card-border-radius;
        }
        &:first-child {
            .product-line-item {
                margin-top: 0;
            }
        }
    }
    .product-line-item {
        position: relative;
    }
    .item-price-qty {
        .col {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .percent-off {
        color: $product-percent-off-price-color;
    }
    .free-shipping-badge {
        color: $success;
        background-color: color-mix(in srgb, currentColor 15%, transparent);
        border: 1px solid color-mix(in srgb, currentColor 25%, transparent);
        padding: $spacer-sm 0px;
        border-radius: $border-radius;
        }
    .quantity,
    .quantity-stepper {
        width: 100%;
        max-width: 90px; // fix for bug in IE11
        margin: $spacer-sm 0;
    }
    .quantity {
        min-width: 50px;
    }
    .line-item-promo > div {
        padding: 0 0 calc(#{$spacer} / 2);
    }
    .minicart-footer {
        padding: $spacer 0;
        border-top: 1px solid $card-body-border-color;
    }
    .estimated-total {
        font-family: $minicart-totals-font-family;
        font-weight: $minicart-totals-font-weight;
        text-transform: $minicart-totals-text-transform;
        p {
            @include paragraph-font-size($minicart-totals-font-size);
        }
    }
    .single-approaching-discount {
        color: $checkout-approaching-discount-color;
        padding-top: calc(#{$spacer} / 2);
    }
}

.minicart-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: map-get($bg, overlay);
    z-index: -1;
    opacity: 0;
    transition: opacity $transition-speed ease;
    &.show {
        z-index: 2;
        opacity: 1;
    }
}