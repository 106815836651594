.product-list-enhancements-header {
    border-bottom: $wishlist-area-card-border-size solid $wishlist-area-card-border-color;
    margin-bottom: $spacer-xl;
    display: flex;
    position: relative;
    top: 0;
    z-index: 1;
    background: white;
    padding: $spacer 0;

    &-name {
        display: flex;
        justify-content: center;
    }

    &-back {
        @include icon($wishlist-modal-header-icon-back, after, $wishlist-area-icon-size, $wishlist-area-icon-color);
    }
}

.product-list-enhancements-create-list-btn {
    display: flex;
    text-transform: uppercase;
    font-weight: 400;
    justify-content: center;
    align-items: center;
}

.product-list-enhancements-create-list-form {
    display: flex;
    flex-direction: column;
    padding: 0 0 30px 0;

    .form-group {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

.product-list-enhancements-toggle-product {
    white-space: nowrap;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:not(.move) {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        z-index: $z-index-wishlist-icon;

        @include media-breakpoint-up(sm) {
            top: 10px;
            right: 10px;
        }
    }

    .product-list-enhancements-icon {
        width: $product-tile-wishlist-link-size;
        height: $product-tile-wishlist-link-size;
        line-height: $product-tile-wishlist-link-size;
        background-color: $product-tile-wishlist-link-background-color;
        box-shadow: $product-tile-wishlist-link-box-shadow;
        border-radius: $product-tile-wishlist-link-border-radius;

        @include icon($product-tile-wishlist-link-icon, before, $product-tile-wishlist-link-icon-sm-size, $product-tile-wishlist-link-color);

        &.selected {
            @include icon($product-tile-wishlist-link-icon-selected, before, $product-tile-wishlist-link-icon-sm-size, $product-tile-wishlist-link-color);
        }

        @include media-breakpoint-up(lg) {
            @include icon($product-tile-wishlist-link-icon, before, $product-tile-wishlist-link-icon-size, $product-tile-wishlist-link-color);

            &.selected {
                @include icon($product-tile-wishlist-link-icon-selected, before, $product-tile-wishlist-link-icon-size, $product-tile-wishlist-link-color);
            }
        }

        &.before {
            &::before {
                @include absolute-center;
                padding-top: 1px;
                padding-left: 1px;
            }
        }
    }
}

.product-list-enhancements-message-empty {
    display: flex;
    text-align: center;
}
