.slider-title {
    text-align: $slider-heading-text-align;
    padding-top: $slider-title-padding-y;
    padding-bottom: $slider-title-padding-y;
    margin-bottom: 0;
    // allow custom page designer classes to override variable
    &:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
        @include headerOrBanner($slider-heading-style, $slider-heading-size);
    }
    [data-slider-controls-position='top'] & {
        line-height: $slider-title-line-height !important; //maintain even spacing for nav controls
    }
    .suggested-products-slider & {
        padding-top: $slider-suggested-products-title-padding-y;
        padding-bottom: $slider-suggested-products-title-padding-y;
        line-height: $slider-suggested-products-title-line-height;
        height: calc(#{$slider-suggested-products-title-line-height + ($slider-suggested-products-title-padding-y * 2)});
        @include headerOrBanner($slider-suggested-products-title-heading-style, $slider-suggested-products-title-heading-size);
        display: flex;
        align-items: center;
        max-width: calc(100% - #{(($slider-button-width + $slider-suggested-products-button-padding) * 2) + $spacer});
    }
}
.slider-container {
    position: relative;
    padding-left: $slider-button-width;
    padding-right: $slider-button-width;
    letter-spacing: 0; // this prevents sizing issues caused by inherited letter-spacing
    max-width: 100vw;
    @include media-breakpoint-down(sm) {
        &:not(.slider-bullets-inside):not(.slider-bullets-hidden) {
            margin-bottom: $bullets-outside-size;
        }
    }
    @include media-breakpoint-only(md) {
        &:not(.slider-bullets-inside-md):not(.slider-bullets-hidden-md) {
            margin-bottom: $bullets-outside-size;
        }
    }
    @include media-breakpoint-up(lg) {
        &:not(.slider-bullets-inside-lg):not(.slider-bullets-hidden-lg) {
            margin-bottom: $bullets-outside-size;
        }
    }
    &:not([class*='slider-thumbnails-vertical']) {
        .slider {
            display: flex; //fix for the initial load of sliders which would stack content and stretch the height of the page
        }
    }
    .slider {
        opacity: 0;
        &.tns-slider {
            opacity: 1;
        }
        &.tns-gallery .tns-slide-active {
            z-index: 1;
        }
    }
    .slide {
        transform: translateZ(0); // fix for text flicker bug in chrome
    }

    // slider nav buttons
    &:not([data-slider-controls-position='top']) {
        .tns-controls button {
            @include vertical-center;
        }
    }
    &[data-slider-controls-position='top'] {
        padding-left: 0px; //pulling the slider back to the width of the container since theres no reason for prev and next button padding
        padding-right: 0px;
        &[class*='next-slide-'] {
            @include media-breakpoint-down(sm) {
                &:not(.next-slide-0) {
                    margin-right: -$spacer; // usually this gets set with class .slider-buttons-hidden but since the buttons in this setting are above the slider, put the neg margins back in place
                    .tns-controls,
                    .tns-nav {
                        padding-right: $spacer;
                    }
                }
            }
            @include media-breakpoint-only(md) {
                &:not(.next-slide-md-0) {
                    margin-right: -$spacer; // usually this gets set with class .slider-buttons-hidden but since the buttons in this setting are above the slider, put the neg margins back in place
                    .tns-controls,
                    .tns-nav {
                        padding-right: $spacer;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                &:not(.next-slide-lg-0) {
                    margin-right: -$spacer; // usually this gets set with class .slider-buttons-hidden but since the buttons in this setting are above the slider, put the neg margins back in place
                    .tns-controls,
                    .tns-nav {
                        padding-right: $spacer;
                    }
                }
            }
        }
        .tns-controls {
            padding: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: calc((#{$slider-title-padding-y * 2} + #{$slider-title-line-height}) * -1);
            height: calc(#{$slider-title-padding-y * 2} + #{$slider-title-line-height});
            margin-right: $spacer;
            @include media-breakpoint-up(md) {
                margin-right: 0;
            }
            button {
                cursor: pointer;
                user-select: none;
                z-index: 2;
                width: $slider-button-width;
                height: 100%;
                background-color: $slider-button-background-controls-position-top;
                border: none;
                display: flex;
                text-align: center;
                transition: background-color $transition-speed ease-out;
                font-size: 0;
                padding: $slider-suggested-products-button-padding 0 $slider-suggested-products-button-padding $slider-suggested-products-button-padding;
                margin-left: $spacer;
                &:before {
                    width: 100%;
                    align-self: center;
                    transition: color $transition-speed ease-out;
                }
                &:focus {
                    outline: none;
                }
                &:hover {
                    background-color: $slider-button-background-hover-controls-position-top;
                    &:before {
                        color: $slider-button-color-hover;
                    }
                }
            }

            .suggested-products-slider & {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: calc((#{$slider-suggested-products-title-padding-y} + #{$slider-suggested-products-title-line-height}) * -1);
                height: calc(#{$slider-suggested-products-title-padding-y} + #{$slider-suggested-products-title-line-height});
                padding-top: 0px;
                padding-bottom: $slider-suggested-products-title-padding-y;
                margin-right: $spacer;
                @include media-breakpoint-up(md) {
                    margin-right: 0;
                }

                button {
                    height: calc(#{$slider-suggested-products-title-padding-y} + #{$slider-suggested-products-title-line-height});
                    padding: $slider-suggested-products-button-padding 0 $slider-suggested-products-button-padding $slider-suggested-products-button-padding;
                }
            }
        }
    }

    .tns-controls button {
        cursor: pointer;
        user-select: none;
        z-index: 2;
        width: $slider-button-width;
        height: $slider-button-width;
        background-color: $slider-button-background;
        border: none;
        display: flex;
        text-align: center;
        transition: background-color $transition-speed ease-out;
        font-size: 0;
        padding: 0;
        &:before {
            width: 100%;
            align-self: center;
            transition: color $transition-speed ease-out;
        }
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: $slider-button-background-hover;
            &:before {
                color: $slider-button-color-hover;
            }
        }
    }
    [data-controls='prev'] {
        @include icon($slider-button-prev-icon, before, $slider-button-icon-size, $slider-button-color);
        left: 0;
    }
    [data-controls='next'] {
        @include icon($slider-button-next-icon, before, $slider-button-icon-size, $slider-button-color);
        right: 0;
    }

    // slider bullets
    .tns-nav {
        @include horizontal-center;
        display: flex;
        bottom: 0;
        z-index: 2;
        bottom: -$bullets-outside-size;

        button {
            display: block;
            background: $slider-bullet-background;
            border: $slider-bullet-border;
            width: $slider-bullet-size;
            height: $slider-bullet-size;
            margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
            padding: 0;
            cursor: pointer;
            transition: transform $transition-speed ease-out;
            &:focus {
                outline: none;
            }
            &.tns-nav-active {
                background: $slider-bullet-background-active;
                border: $slider-bullet-border-active;
                transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
            }
        }

        &.with-pagination-btn {
            button {
                order: 1;
            }

            .with-pagination {
                order: 0;
            }
        }

    }

    &.pagination-style-dots {
        .tns-nav {
            align-items: center;

            button {
                border-radius: 50%;
            }
        }
    }

    &.pagination-style-shortLines {
        .tns-nav {
            align-items: flex-end;

            button {
                background-color: $slider-short-lines-bullet-background-color;
                border: none;
                width: $slider-short-lines-bullet-size-width;
                height: $slider-short-lines-bullet-size-height;

                &.tns-nav-active,
                &:hover:not(.tns-nav-active) {
                    background-color: $slider-short-lines-bullet-active-background-color;
                    height: $slider-short-lines-bullet-active-size-height;
                    transition: background-color $transition-speed ease-in-out, height $transition-speed ease-in-out;
                }
            }
        }
    }

    &.pagination-style-longLines {
        .tns-nav {
            align-items: flex-end;
            width: 100%;
            button {
                background-color: $slider-long-lines-bullet-background-color;
                border: none;
                width: $slider-long-lines-bullet-size-width;
                height: $slider-long-lines-bullet-size-height;
                margin: 0;
                flex: auto;
                &.tns-nav-active,
                &:hover:not(.tns-nav-active) {
                    background-color: $slider-long-lines-bullet-active-background-color;
                    height: $slider-long-lines-bullet-active-size-height;
                    transition: background-color $transition-speed ease-in-out, height $transition-speed ease-in-out;
                }
            }
        }
    }

    // themes
    &.light:not([data-slider-controls-position='top']) {
        .tns-controls button {
            background-color: $slider-light-button-background;
            &::before {
                color: $slider-light-button-color;
            }
            &:hover {
                background-color: $slider-light-button-background-hover;
                &::before {
                    color: $slider-light-button-color-hover;
                }
            }
        }
        .tns-nav {
            button {
                background: $slider-light-bullet-background;
                border: $slider-light-bullet-border;
                &.tns-nav-active  {
                    background: $slider-light-bullet-background-active;
                    border: $slider-light-bullet-border-active;
                }
            }
        }
        .tns-nav-controller {
            &::before {
                color: $slider-light-bullet-play-icon-color;
            }
            &.pause::before {
                color: $slider-light-bullet-pause-icon-color;
            }
        }
        &.pagination-style-shortLines {
            .tns-nav {
                button {
                    background-color: $slider-light-short-lines-bullet-background-color;
                    &.tns-nav-active,
                    &:hover:not(.tns-nav-active) {
                        background-color: $slider-light-short-lines-bullet-active-background-color;
                    }
                }
            }
        }
    }

    [data-action='stop'],
    [data-action='start'] {
        display: none;
    }

    // style helper classes
    @include media-breakpoint-down(sm) {
        &.slider-buttons-hidden {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden {
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside {
            padding-left: 0;
            padding-right: 0;
        }
        &.slider-bullets-inside {
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
                &.slider-buttons-hidden {
                    margin-right: -$spacer;
                }
            }
            &.prev-slide-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
                &.slider-buttons-hidden {
                    margin-left: -$spacer;
                }
            }
        }
    }
    @include media-breakpoint-only(md) {
        &.slider-buttons-hidden-md {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden-md {
            margin-bottom: 0;
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside-md {
            padding-left: 0;
            padding-right: 0;
        }
        &.slider-bullets-inside-md {
            margin-bottom: 0;
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-md-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
            }
            &.prev-slide-md-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.slider-buttons-hidden-lg {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden-lg {
            margin-bottom: 0;
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside-lg {
            padding-left: 0;
            padding-right: 0;
        }
        &.slider-bullets-inside-lg {
            margin-bottom: 0;
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-lg-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
            }
            &.prev-slide-lg-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
            }
        }
    }
}

// Slider Pause/Play Button
.tns-nav-controller {
    cursor: pointer;
    @include icon($video-pause-icon, before, $slider-bullet-controller-size, $slider-bullet-play-icon-color);
    &.pause {
        @include icon($video-play-icon, before, $slider-bullet-controller-size, $slider-bullet-pause-icon-color);
    }
    &:not(.with-pagination) {
        position: absolute;
        padding: $slider-bullet-controller-padding;
        z-index: 2;
    }
    &.top-right {
        top: $slider-bullet-controller-inset-amount;
        right: $slider-bullet-controller-inset-amount;
    }
    &.top-left {
        top: $slider-bullet-controller-inset-amount;
        left: $slider-bullet-controller-inset-amount;
    }
    &.bottom-right {
        bottom: $slider-bullet-controller-inset-amount;
        right: $slider-bullet-controller-inset-amount;
    }
    &.bottom-left {
        bottom: $slider-bullet-controller-inset-amount;
        left: $slider-bullet-controller-inset-amount;
    }

    &.with-pagination {
        padding-right: $slider-bullet-with-pagination-padding-right;
    }
}

// Thumbnails
.slider-thumbnails {
    padding-left: $slider-thumbnail-button-icon-size + $spacer;
    padding-right: $slider-thumbnail-button-icon-size + $spacer;
    user-select: none;
    .slider {
        overflow: auto;
        text-align: center;
        padding-bottom: 50px;
        margin-bottom: -50px;
    }
    .slide {
        background-color: $white;
        min-width: $slider-thumbnail-width;
        max-width: $slider-thumbnail-width;
        flex-basis: $slider-thumbnail-width;
        max-height: $slider-thumbnail-width; // fix for no-image alt text increasing height
        overflow: hidden;
        @include media-breakpoint-up(md) {
            min-width: $slider-thumbnail-width-md;
            max-width: $slider-thumbnail-width-md;
            flex-basis: $slider-thumbnail-width-md;
            max-height: $slider-thumbnail-width-md;
        }
        @include media-breakpoint-up(lg) {
            min-width: $slider-thumbnail-width-lg;
            max-width: $slider-thumbnail-width-lg;
            flex-basis: $slider-thumbnail-width-lg;
            max-height: $slider-thumbnail-width-lg;
        }
        &>* {
            width: 100%;
        }
        &::after {
            content: '';
            pointer-events: none;
            @include absolute-cover;
            border-style: solid;
            border-width: $slider-thumbnail-border-width;
            border-color: $slider-thumbnail-border-color;
            transition: border $transition-speed ease-out;
            border-radius: $slider-thumbnail-active-border-border-radius; // border squares off before fading without this
        }
        &.tns-nav-active {
            background-color: $white;
            img {
                padding: $slider-thumbnail-active-padding;
            }

            &::after {
                border-width: $slider-thumbnail-border-active-width;
                border-color: $slider-thumbnail-border-active-color;
                border-radius: $slider-thumbnail-active-border-border-radius;
            }
        }
    }
    &.slider-thumbnail-dots {
        .slide {
            @include media-breakpoint-down(sm) {
                min-width: $slider-bullet-size;
                max-width: $slider-bullet-size;
                height: $slider-bullet-size;
                margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
                &:focus {
                    outline: none;
                }
                &:after {
                    background: $slider-bullet-background;
                    border: $slider-bullet-border;
                    border-radius: $slider-thumbnail-dots-border-radius;
                }
                &.tns-nav-active::after {
                    background: $slider-bullet-background-active;
                    border: $slider-bullet-border-active;
                    transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
                }
                img {
                    display: none !important; // hides images after js reloads them from a new variant
                }
            }
            .primary-images-thumbnails-dots & {
                min-width: $slider-bullet-size;
                max-width: $slider-bullet-size;
                height: $slider-bullet-size;
                margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
                &:focus {
                    outline: none;
                }
                &:after {
                    background: $slider-bullet-background;
                    border: $slider-bullet-border;
                    border-radius: $slider-thumbnail-dots-border-radius;
                }
                &.tns-nav-active::after {
                    background: $slider-bullet-background-active;
                    border: $slider-bullet-border-active;
                    transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
                }
                img {
                    display: none !important; // hides images after js reloads them from a new variant
                }
            }
        }
        .slider-thumbnail-controls {
            display: none;
            .primary-images-thumbnails & {
                display: block; // allow controls to show on PDP gallery thumbnail slider
            }
        }
    }
    &.slider-thumbnail-lines {
        @include media-breakpoint-down(sm) {
            .slider {
                border-radius: $slider-long-lines-bullet-size-height;

                .slide {
                    width: calc(100% / var(--thumbnail-product-slider-number-of-slides));
                    height: $slider-long-lines-bullet-size-height;
                    margin: 0;
                    border-radius: 0;
                    max-width: unset;
                    min-width: unset;

                    &:focus {
                        outline: none;
                    }
                    &:after {
                        background: $slider-bullet-background;
                        border: $slider-bullet-border;
                        border-radius: 0;
                    }
                    &.tns-nav-active::after {
                        background: $slider-bullet-background-active;
                        border: $slider-bullet-border-active;
                    }
                    img {
                        display: none !important; // hides images after js reloads them from a new variant
                    }
                }
            }
        }
    }

    .tns-horizontal {
        &>.tns-item {
            vertical-align: middle;
        }
    }
    .slider-thumbnail-controls {
        .prev,
        .next {
            position: absolute;
            top: 0;
            height: 100%;
            width: $slider-thumbnail-button-size;
            cursor: pointer;
            user-select: none;
            background-color: $slider-thumbnail-button-background-color;
            transition: opacity $transition-speed ease-out;
            &::before {
                @include vertical-center;
            }
            &:hover {
                background-color: $slider-thumbnail-button-background-color-hover;
                &::before {
                    color: $slider-thumbnail-button-color-hover;
                }
            }
            &[aria-disabled=true] {
                opacity: 0;
            }
        }
        .prev {
            @include icon($slider-thumbnail-prev-icon, before, $slider-thumbnail-button-icon-size, $slider-thumbnail-button-color);
            left: 0;
        }
        .next {
            @include icon($slider-thumbnail-next-icon, before, $slider-thumbnail-button-icon-size, $slider-thumbnail-button-color);
            right: 0;
        }
    }
    // IE11 overrides: tiny-slider serves IE-specific styles that don't work very well
    .tns-horizontal.tns-no-subpixel {
        white-space: nowrap;
        &>.tns-item {
            float: none;
            display: inline-block;
            margin: 0;
            margin-left: 0 !important;
        }
    }

    // video assets in pdp image gallery
    .video-container {
        .video-thumbnail-button::before {
            font-size: $slider-thumbnail-play-icon-size;
        }
    }

    // Vertical thumbnails: need to override Tiny Slider's injected styles, since they don't support axis changes at breakpoints
    @include media-breakpoint-down(sm) {
        &.slider-thumbnails-vertical {
            @include vertical-slider-thumbnails;
            height: var(--thumbnail-product-slider-max-height, 100%);
        }
    }
    @include media-breakpoint-only(md) {
        &.slider-thumbnails-vertical-md {
            @include vertical-slider-thumbnails;
            height: var(--thumbnail-product-slider-max-height-md, 100%);
        }
    }
    @include media-breakpoint-up(lg) {
        &.slider-thumbnails-vertical-lg {
            @include vertical-slider-thumbnails;
            height: var(--thumbnail-product-slider-max-height-lg, 100%);
        }
    }
}

//adding css custom properties here to adjust max thumbnail slider height on pdp
:root {
    --slider-thumbnail-width: #{$slider-thumbnail-width};
    --slider-thumbnail-width-md: #{$slider-thumbnail-width-md};
    --slider-thumbnail-width-lg: #{$slider-thumbnail-width-lg};
    --slider-thumbnail-spacing: #{$slider-thumbnail-spacing};
}

.product-detail .primary-images .primary-images-thumbnails {
    .slider-thumbnails.slider-thumbnails-vertical-md,
    .slider-thumbnails.slider-thumbnails-vertical-lg {
        //don't change these classes for mobile
        @include media-breakpoint-up(md) {
            .tns-outer {
                max-height: var(--thumbnail-product-slider-max-height-md, 100%);
                @include media-breakpoint-up(lg) { max-height: var(--thumbnail-product-slider-max-height-lg, 100%); }

                &.prev-button-showing {
                    .tns-inner {
                        margin-top: calc(var(--slider-thumbnail-width) + var(--slider-thumbnail-spacing)) !important;
                        //changing the max height here to substract the margin added so the 'active' slide is always in-view
                        max-height: calc(var(--thumbnail-product-slider-max-height-md, 100%) - (var(--slider-thumbnail-width) + var(--slider-thumbnail-spacing)));
                        @include media-breakpoint-up(lg) { max-height: calc(var(--thumbnail-product-slider-max-height-lg, 100%) - (var(--slider-thumbnail-width) + var(--slider-thumbnail-spacing))); }

                        .slider {
                            margin-top: calc((var(--slider-thumbnail-width) + var(--slider-thumbnail-spacing)) * -1);
                        }
                    }
                }
            }
            .slider-thumbnail-controls {
                .prev, .next {
                    top: unset;
                    bottom: unset;
                    margin-top: $slider-thumbnail-spacing;
                    background: $white;
                    height: $slider-thumbnail-button-size;
                    width: $slider-thumbnail-button-size;
                    border-radius: $slider-thumbnail-width;
                    left: calc((#{$slider-thumbnail-width - $slider-thumbnail-button-size}) / 2);
                    @include media-breakpoint-up(md) {
                        border-radius: $slider-thumbnail-width-md;
                        left: calc((#{$slider-thumbnail-width-md - $slider-thumbnail-button-size}) / 2);
                    }
                    @include media-breakpoint-up(lg) {
                        border-radius: $slider-thumbnail-width-lg;
                        left: calc((#{$slider-thumbnail-width-lg - $slider-thumbnail-button-size}) / 2);
                    }
                    &:before {
                        top: unset;
                        bottom: unset;
                        left: 0;
                        width: $slider-thumbnail-button-size;
                        height: $slider-thumbnail-button-size;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .next {
                    top: var(--thumbnail-product-slider-max-height, 100%);
                    @include media-breakpoint-up(md) { top: var(--thumbnail-product-slider-max-height-md, 100%); }
                    @include media-breakpoint-up(lg) { top: var(--thumbnail-product-slider-max-height-lg, 100%); }
                }
            }
        }
    }
}