@import "~client_core/components/header";

.header,
.header-banner {
    .store-selector {
        .store-locator-header-button {
            @include banner(5);
            @include media-breakpoint-up(md) {
                line-height: 10px;
                text-transform: uppercase;
            }
            @include media-breakpoint-up(lg) {
                line-height: 10px;
                text-transform: uppercase;
            }
            &:before {
                position: absolute;
                transform: translateX(-16px);
            }
        }
    }
}