.veil {
    position: absolute;
    z-index: $z-index-modal-backdrop;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: $black;
    }
    + .veil {
        display: none;
    }
}

.spinner {
    width: $spinner-size;
    height: $spinner-size;
    text-align: center;
    animation: spincounterclockwise 2s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $spinner-size / 2 * -1;
    margin-left: $spinner-size / 2 * -1;
    @include background-logo-svg($spinner-background-color);
}

.dot1,
.dot2 {
    display: none !important;
}

@keyframes sk-rotate {
    100% { transform: rotate(360deg); }
}

@keyframes sk-bounce {
    0%,
    100% { transform: scale(0); }
    50% { transform: scale(1); }
}
