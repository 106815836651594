footer {
    background-color: $footer-background;
    border-bottom: $footer-border-bottom-width solid $footer-border-bottom-color;
    padding-bottom: $footer-padding-bottom;
    padding-top: $footer-padding-top;
    margin-top: $footer-margin-top;
    ul {
        list-style: none;
        padding-left: 0;
        padding-top: 12px;
    }
    .title {
        position: relative;
        color: $footer-title-font-color;
        @include header(6);
        @include media-breakpoint-up(md) {
            @include header(4);
            margin-bottom: $spacer;
        }
    }
    .content {
        font-size: $footer-link-font-size;
        li {
            height: $footer-link-height;
            margin-bottom: $spacer;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .store {
        padding-left: 8px;
        padding-right: 8px;
        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 8px;
        }
        .btn {
            margin-bottom: $spacer;
            margin-top: $spacer;
        }
        .content {
            display: none;
        }
        @include media-breakpoint-up(md) {
            border: none;
            .btn {
                margin-top: 5px;
            }
            .content {
                display: block;
            }
        }
        .locate-store {
            padding-left: 16px;
            &:before {
                font-size: 16px;
                line-height: normal;
                margin-top: 3px;
            }
        }
    }
    .social {
        padding-left: 8px;
        padding-right: 8px;
        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .back-to-top {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            right: $spacer;
        }
        .fa-stack {
            width: $footer-backtotop-size;
            height: $footer-backtotop-size;
            line-height: $footer-backtotop-size;
        }

        .fa-arrow-up {
            @include icon($icon-arrow-top, before, 20px, $footer-backtotop-icon-color);
            width: 100%;
            height: 100%;
        }

        .fa-circle {
            background-color: $footer-backtotop-color;
            box-shadow: $footer-backtotop-shadow;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .copyright,
    .postscript {
        color: $footer-copyright-color;
        font-size: $footer-copyright-font-size;
        padding-bottom: $spacer;
        a {
            color: $footer-copyright-color;
            text-decoration: none;
        }
    }
    hr {
        border-color: $footer-hr-color;
        margin: $footer-hr-margin;
    }
    a, .text-link {
        color: $footer-link-color;
        &:hover {
            color: $footer-link-color;
            text-decoration: underline;
        }
    }
}

.footer-item {
    &.collapsible-sm {
        padding-left: 0;
        padding-right: 0;
        ~ .collapsible-sm {
            margin-top: 0;
        }
        .content {
            margin-bottom: 0;
            padding-left: 32px;
        }
        .title {
            padding: 24px 16px 24px 32px;
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                margin-bottom: 8px;
                @include header(4);
            }
            &::after {
                color: $footer-title-font-color;
                font-size: $footer-title-font-size;
                right: 0;
            }
        }
        button {
            text-align: left;
            display: block;
            width: 100%;
            &:focus {
                z-index: 1; // keep focus outline visible
            }
        }
        @include media-breakpoint-up(md) {
            .title {
                border-bottom: 0;
                padding: 0;
            }
            button {
                cursor: text;
            }
        }
    }
    @include media-breakpoint-up(md) {
        &.collapsible-sm {
            border: none;
            padding-left: $spacer;
            padding-right: $spacer;

            .content {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.email-signup-form {
    padding-left: 8px;
    padding-right: 8px;
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 8px;
    }
    .form-control {
        color: map-get($content, inverse);
        &::placeholder {
            color: map-get($content, inverse);
        }
        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px map-get($bg, inverse) inset;
            -webkit-text-fill-color: map-get($content, inverse);
            border: none;
        }
    }
    .inline-btn {
        &:hover,
        &:focus {
            border-color: map-get($content, inverse);
        }
        input {
            border-width: 0px;
        }
    }
    .email-success {
        font-weight: bold;
        font-size: $footer-signup-font-size;
        line-height: $footer-signup-line-height;
        letter-spacing: $footer-signup-letter-spacing;
    }
    .email-description {
        @include banner(3);
        color: map-get($content, inverse);
        margin-bottom: 8px;
    }
    .btn {
        @include icon($icon-arrow-top, after, 16px, map-get($content, inverse));
        &::after {
            transform: rotate(90deg);
        }
    }

    .email-success,
    .email-error {
        display: none;
    }

    &.submit-success {
        .email-success {
            display: block;
        }
        .email-description,
        .email-input {
            display: none;
        }
    }

    &.submit-error {
        .email-error {
            display: block;
        }
    }
}

.newsletter-subscription-modal {
    font-size: $base-font-size;
    text-align: center;
}
