@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            border-color: $collapsible-border-color;

            &.inverse {
                .card-header,
                .title,
                .title.card-title {
                    background: $collapsible-inverse-header-background;
                    color: $collapsible-inverse-header-color;
                    &:after {
                        color: $collapsible-inverse-header-icon-color;
                    }
                }
                .card-body,
                .content {
                    background-color: $collapsible-inverse-body-background;
                    color: $collapsible-inverse-body-color;
                }
            }

            .card-header {
                padding: 0;
                border-color: $collapsible-border-color;
                border-radius: 0;
                &:first-child {
                    border-radius: 0;
                }
                &:last-child {
                    border-radius: 0;
                }
                button {
                    text-align: left;
                    display: block;
                    width: 100%;
                    &:focus {
                        z-index: 1; // keep focus outline visible
                    }
                }
            }
            .title,
            .title.card-title {
                @include icon($collapsible-header-icon-expand, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                background-color: $collapsible-header-background;
                color: $collapsible-header-color;
                padding-top: $collapsible-header-padding-top * 0.75;
                padding-right: $collapsible-header-padding-right;
                padding-bottom: $collapsible-header-padding-bottom * 0.75;
                padding-left: $collapsible-header-padding-left;
                user-select: none;
                border-width: 0;
                cursor: pointer;
                @include headerOrBanner($collapsible-title-header-or-banner, $collapsible-title-header-value);

                //SAS-8 fix for aligning collapsible content and accordion for pdp
                .main-content-group & {
                    .product-detail & {
                        @include headerOrBanner($collapsible-title-header-or-banner, $collapsible-title-header-value);
                    }
                }

                @include media-breakpoint-up(lg) {
                    padding-top: $collapsible-header-padding-top;
                    padding-right: $collapsible-header-padding-right;
                    padding-bottom: $collapsible-header-padding-bottom;
                    padding-left: $collapsible-header-padding-left;
                }

                display: flex;
                align-items: center;
                &::after {
                    position: absolute;
                    top: unset;
                    left: 0;
                }
            }
            .content,
            &.card .card-header ~ .card-body,
            .card-body {
                display: none;
                //background-color: $collapsible-body-background; //inherit from card styling
                //color: $collapsible-body-color; //inherit from card styling
                border-radius: 0;
                padding-top: $collapsible-body-padding-top;
                padding-bottom: $collapsible-body-padding-bottom;
                padding-left: calc( #{$spacer} / 2);
                padding-right: calc( #{$spacer} / 2);

                @include media-breakpoint-up(md) {
                    padding-right: $collapsible-body-padding-right;
                    padding-left: $collapsible-body-padding-left;
                }
                &:first-child {
                    border-radius: 0;
                }
                &:last-child {
                    border-radius: 0;
                    border-bottom: none;
                }
            }
            &:not(:last-child) {
                margin-bottom: 0;
                border-bottom: none;
                border-radius: 0;
                &+.collapsible-#{$size} {
                    border-radius: 0;
                }
            }
            &.aside,
            &.no-borders {
                .title::after {
                    right: 0;
                    left: unset;
                }
            }
            &.aside .title::after {
                top: $card-aside-header-padding-top;
            }
            &.no-borders .title::after {
                top: $card-header-padding-top;
            }
            &:first-child {
                .card-header,
                .card-title {
                    border-radius: calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px)  0 0 ;
                }
            }
            &:last-child {
                .card-header,
                .card-title,
                .card-body {
                    border-radius: 0 0 calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px);
                }
                &:not(.active):not(.aside):not(.no-borders) {
                    .card-header {
                        border-bottom: 1px solid $collapsible-border-color;
                    }
                }
            }
            &.active {
                > .card-header > .title,
                > .title,
                > .content-asset > .title {
                    @include icon($collapsible-header-icon-close, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                }
                > .content,
                > .content-asset > .content,
                > .card-body,
                &.card .card-header ~ .card-body {
                    display: block;
                    > .content:not(.values) { // prevent from affecting plp refinements
                        display: block;
                    }
                }
                &:last-child {
                    .card-header,
                    .card-title {
                        border-radius: 0;
                        border-bottom: none;
                    }
                    &:not(.aside):not(.no-borders) {
                        .card-body {
                            border-bottom: 1px solid $collapsible-border-color;
                        }
                    }
                }
                &.inverse {
                    > .card-header > .title,
                    > .title,
                    > .content-asset > .title {
                        @include icon($collapsible-header-icon-close, after, $collapsible-header-icon-size, $collapsible-inverse-header-icon-color);
                    }
                }
            }
        }
    }
}

.accordion {
    .accordion-cart-promo & {
        .card {
            border: none;
        }
    }
    .card {
        margin-bottom: 0;
        border-color: $accordion-border-color;
        .card-header {
            background: $accordion-header-background;
            color: $accordion-header-color;
            [type="button"] {
                text-align: $accordion-header-text-align;
                padding-top: $collapsible-header-padding-top;
                padding-right: $collapsible-header-padding-right;
                padding-bottom: $collapsible-header-padding-bottom;
                padding-left: $collapsible-header-padding-left;
                width: 100%;
                @include icon($accordion-header-icon-close, after, $accordion-header-icon-size, $accordion-header-icon-color);

                &.collapsed {
                    @include icon($accordion-header-icon-expand, after, $accordion-header-icon-size, $accordion-header-icon-color);
                }

                display: flex;
                align-items: center;
                &::after {
                    position: absolute;
                    top: unset;
                    left: 0;
                }
                .accordion-cart-promo & {
                    padding-top: calc(#{$spacer} / 2);
                    padding-bottom: calc(#{$spacer} / 2);
                    padding-left: 0;
                    font-family: $checkout-totals-font-family;
                    font-size: $checkout-totals-font-size;
                    font-weight: $checkout-totals-font-weight;
                    text-transform: $checkout-totals-text-transform;
                    &::after {
                        position: relative;
                        top: unset;
                        left: $spacer-xl;
                        right: unset;
                    }
                }
            }

            &.no-icon {
                [type="button"] {
                    &:after {
                        content: '';
                    }

                    &.collapsed {
                        &:after {
                            content: '';
                        }
                    }
                }
            }

            //SAS-8 fix for aligning collapsible content and accordion for pdp
            .title.card-title {
                @include headerOrBanner($collapsible-title-header-or-banner, $collapsible-title-header-value);
            }

        }
        &.active {
            .card-header {
                [type="button"] {
                    @include icon($accordion-header-icon-close, after, $accordion-header-icon-size, $accordion-header-icon-color);
                }
            }
        }
        &[class*="collapsible"]:not(.active) {
            .card-header {
                [type="button"] {
                    @include icon($accordion-header-icon-expand, after, $accordion-header-icon-size, $accordion-header-icon-color);
                }
            }
        }
        .card-header,
        .card-body {
            border-top: 1px solid $accordion-body-border-color;
        }
        .card-body {
            // padding and margins at this level cause the collapse animations to be janky
            padding: 0;
            margin: 0;
            background-color: $accordion-body-background;
            color: $accordion-body-color;
            padding-top: $collapsible-body-padding-top;
            padding-bottom: $collapsible-body-padding-bottom;
            padding-left: calc( #{$spacer} / 2);
            padding-right: calc( #{$spacer} / 2);

            @include media-breakpoint-up(md) {
                padding-right: $collapsible-body-padding-right;
                padding-left: $collapsible-body-padding-left;
            }
        }
        &:first-child {
            .card-header,
            .card-body {
                &:first-child {
                    border-top: 0;
                }
            }
        }
        //sfra fix
        &:only-child:last-of-type {
            border-top: 1px solid $accordion-body-border-color;
            border-bottom: 1px solid $accordion-body-border-color;
            border-radius: calc(#{$card-border-radius} - 1px);
        }
    }

    &.inverse {
        .card {
            .card-header,
            .title {
                background: $accordion-inverse-header-background;
                color:$accordion-inverse-header-color;
                border-top: none;
                &:after {
                    color: $collapsible-inverse-header-color;
                }
                &.collapsed {
                    &:after {
                        color: $collapsible-inverse-header-color;
                    }
                }
            }
            .card-body {
                background: $accordion-inverse-body-background;
                color: $accordion-inverse-body-color;
            }
        }
    }

    &.no-icon {
        .card {
            .card-header {
                [type="button"] {
                    &:after {
                        content: '';
                    }

                    &.collapsed {
                        &:after {
                            content: '';
                        }
                    }
                }
            }
        }
    }
}
