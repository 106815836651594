// footer social media links
.social-links {
    list-style: none;
    display: flex;
    flex-flow: wrap;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 0;
    li {
        flex-basis: 10%;
        @include media-breakpoint-up(md) {
            flex-basis: 16%;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    a {
        font-size: $footer-icon-size;
        line-height: $footer-icon-size;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    .fa-linkedin-square {
        @include icon($icon-linkedin);
    }
    .fa-facebook-square {
        @include icon($icon-facebook);
    }
    .fa-twitter-square {
        @include icon($icon-twitter);
    }
    .fa-youtube-square {
        @include icon($icon-youtube);
    }
    .fa-tiktok-square {
        @include icon($icon-tiktok);
    }
    .fa-pinterest-square {
        @include icon($icon-pinterest);
    }
    .fa-email-square {
        @include icon($icon-email);
    }
    .fa-link-square {
        @include icon($icon-link);
    }
    .fa-instagram-square {
        @include icon($icon-instagram);
    }
    .fa-email {
        @include icon($icon-email);
    }
    .share-link {
        font-size: initial;
    }
}

// general styles for social share links (used on PDP and in Page Designer)
.social-icons {
    @include remove-list-styling();
    .fa-pinterest {
        @include icon($icon-pinterest, before);
    }
    .fa-facebook-official {
        @include icon($icon-facebook, before);
    }
    .fa-twitter {
        @include icon($icon-twitter, before);
    }
    .fa-link {
        @include icon($icon-link, before);
    }
    .share-link {
        text-decoration: none;
        display: block;
    }
}
