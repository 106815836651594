@import "~core/components/pageHeader";

.page-header {
    &.search-results-header {
        .page-header-title {
            @include header($page-title-header-size, $page-title-header-font-size-alteration);
        }
    }
}

.page-title {
    @include header($page-title-header-size, $page-title-header-font-size-alteration);
}
