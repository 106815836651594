@import "~core/components/wishlist/card";

.product-list-enhancements-card-list-body {
    container-type: inline-size;
}
.product-list-enhancements-card-list-body-image {
    max-height: calc((100cqw / 4) - ((#{$card-body-padding-right + $card-body-padding-left + $spacer}) /  4));
}

.product-list-enhancements-card-product-form {
    .quickview.btn,
    .product-list-enhancements-card-product-add {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
