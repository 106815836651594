@import "~client_core/product/grid";


.refinement:not(.aside) {
    &.card {
        .card-header {
            //SAS Change: changing @include header => banner
            .title.card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                @include banner($product-refinement-header-value);
            }
        }
    }
}

.refinements .collapsible-xl .title::after,
.collapsible-xl .title.card-title::after {
    left: $spacer-sm;
}

.refinement-bar {
    .product-sort {
        select {
            @include media-breakpoint-up(lg) {
                background-position: calc(100% - #{$product-refinement-horizontal-multipledropdowns-btn-padding-left}) center;
            }
        }
    }
}