@import "~client_core/account/account";

.account-page {
    &.container,
    .container {
        @include media-breakpoint-up(lg) {
            padding-right: $spacer-xl;
            padding-left: $spacer-xl;
        }
    }
}

.linc-optin-widget {
    margin-bottom: $spacer;
}

.login-page .login-nav-tabs .nav-link {
    font: $tab-font;
    text-transform: $tab-text-transform;
    font-weight: $tab-font-weight;
    &.active,
    &:not(.active) {
        border-color: $tab-border-color;
    }
}

.validator-container .password-validation-helper li.valid {
    color: $green;
}