.modal {
    z-index: $z-index-modal;
    .modal-content {
        background-color: $modal-background-color;
        border: $modal-border;
        border-radius: $modal-border-radius;
        box-shadow: $modal-box-shadow;
    }
    .modal-header {
        padding: $modal-header-padding $modal-header-padding calc(#{$modal-header-padding} / 2);
        background-color: $modal-header-background-color;
        color: $modal-header-text-color;
        border-bottom-color: $modal-header-border-color;
        border-top-left-radius: calc(#{$modal-border-radius} - 1px);
        border-top-right-radius: calc(#{$modal-border-radius} - 1px);
        .modal-title {
            @include header($modal-header-title-size);
            align-self: center;
            overflow-wrap: anywhere;
        }
        .close {
            @include icon($icon-close, before, $modal-header-close-icon-size, $modal-header-text-color);
            line-height: 55%;
            margin: 0;
            padding: 0;
            text-shadow: none;
            opacity: 1;
            [aria-hidden=true] {
                display: none;
            }
        }
        a {
            color: $modal-header-text-color;
        }
    }
    .modal-body {
        flex: 0 0 auto;
        padding: calc(#{$modal-header-padding} / 2) $modal-body-padding $modal-body-padding;
        color: $modal-body-text-color;
        background-color: $modal-body-background-color;
    }
    .modal-footer {
        padding: $modal-footer-padding;
        color: $modal-footer-text-color;
        background-color: $modal-footer-background-color;
        border-top-color: $modal-footer-border-color;
        border-bottom-left-radius: calc(#{$modal-border-radius} - 1px);
        border-bottom-right-radius: calc(#{$modal-border-radius} - 1px);
        justify-content: flex-start;
        .button-wrapper {
            .btn + .btn {
                margin-left: $spacer;
            }
        }
        &>.btn + .btn {
            margin-left: $spacer-xl;
        }
    }
    &.show {
        &~.modal.show {
            z-index: $z-index-modal + 2;
        }
    }
}

.dropdown-modal {
    .outer-container {
        background-color: $dropdown-modal-background-color;
        padding: $dropdown-modal-background-padding;
        position: relative;
        transition: $dropdown-modal-transition;
        max-height: 0px;
        will-change: max-height;
        overflow: auto;
    }
    .container {
        max-width: $dropdown-modal-content-max-width;
        .modal-header {
            background: $dropdown-modal-header-background-color;
        }
    }
    &.slide {
        max-height: 0px;
        will-change: max-height;
        overflow: hidden;
        opacity: 1;
        transition: $dropdown-modal-transition;
    }
    &.show {
        max-height: 100%;
        .outer-container {
            max-height: 100%;
        }
        .modal-dialog {
            pointer-events: auto;
        }
    }
    &.bm-show {
        .page[data-action="null"] & { //a fallback to only use these values on BM page-designer pages
            max-height: 100%;
            .outer-container {
                max-height: 100%;
            }
        }
    }
}

.modal-backdrop {
    background-color: $modal-backdrop-background-color;
    &.show {
        opacity: 1;
        z-index: $z-index-modal-backdrop;
        &~.modal-backdrop.show {
            z-index: $z-index-modal + 1;
        }
        &+ .modal.show {
            z-index: $z-index-modal + 2;
        }
    }
}

// Modal sizes
.modal {
    &-dialog {
        @include modal-size;
    }
    &-sm {
        @include modal-size(sm);
    }
    &-lg {
        @include modal-size(lg);
    }
    &-xl {
        @include modal-size(xl);
    }
    &-full-screen {
        max-width: 100vw;
        margin: 0;
        display: flex;
        height: 100%;
        align-items: center;
        min-height: 100vw;

        @include media-breakpoint-up(lg) {
            min-height: var(--hi-res-image-width, 1600px);
        }

        .modal-content {
            background-color: $modal-body-background-color;
            display: flex;
            align-items: center;
        }
        .modal-body {
            display: flex;
            align-items: center;
            max-width: var(--hi-res-image-width, 1600px);
            padding: 0;

            @include media-breakpoint-up(sm) {
                padding: $modal-body-padding;
            }
        }
        .icon-close {
            position: absolute;
            color: $modal-body-text-color;
            background-color: $modal-body-background-color;
            font-size: $modal-body-icon-close-font-size;
            padding: $spacer;
            border-radius: $modal-body-icon-close-border-radius;
            top: 0;
            right: 0;

            @include media-breakpoint-up(sm) {
                top: calc(#{$modal-body-icon-close-position-top} / 2);
                right: calc(#{$modal-body-icon-close-position-right} / 2);
            }
            @include media-breakpoint-up(md) {
                top: $modal-body-icon-close-position-top;
                right: $modal-body-icon-close-position-right;
            }
        }
    }
}

.modal-scrollbody {
    overflow: hidden;
    .modal-body {
        overflow-x: hidden;
        overflow-y: auto;
    }
}
