@import "~core/components/video";

.video-container {
    &.aspect-ratio-auto {
        @include aspect-ratio(100%);
        .video-thumbnail:not(.btn) {
            @include aspect-ratio(100%);
        }
    }
}
