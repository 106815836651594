//American Tourister Changes Commented
@import "~client_core/components/tables";
/*
* PAGE DESIGNER LAYOUT GRID SPACING
*/
[class*='experience-commerce_layouts'] {

    // vertical spacing between layout containers
    &+[class*='experience-commerce_layouts'] > div:first-child {
        margin-top: $page-designer-layout-spacing;
    }

    // vertical spacing between layout items on mobile
    .region[class*='col-md'] {
        @include media-breakpoint-down(sm) {
            // single column in mobile
            &+.region[class*='col-12']:not(.order-1),
            &.order-2[class*='col-12'] {
                margin-top: $spacer*2;
            }
            // 2 column in mobile
            &+.region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
                margin-top: $spacer*2;
            }
            // 3 column in mobile
            &+.region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                margin-top: $spacer*2;
            }
        }
    }

    // make sure contents of 2 column or align-self-stretch fill container vertically
    .region {
        &[class*='col-md-6'],
        &.align-self-stretch {
            .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
            [class*='experience-commerce_assets'] > div {
                height: auto;
                &:only-child {
                    height: 100%;
                }

                + .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']),
                [class*='experience-commerce_assets'] > div {
                    height: auto;
                    &:only-child {
                        height: 100%;
                    }
                }
            }
        }
        &.align-self-stretch {
            //make sure the columns in the 'stretch' layout actually meet the same height
            > [class*='experience-commerce_layouts'] {
                height: 100%;
                //make sure the columns contained within in the 'stretch' layout actually meet the same height
                > [class*='-1c'] {
                    height: 100%;
                }
            }
        }
    }

    //enables setting class on layout's custom class
    .no-gutters  {
        .row {
            padding-right: $spacer;
            padding-left: $spacer;
            @include media-breakpoint-up(xl) {
                padding-right: $spacer-xl;
                padding-left: $spacer-xl;
            }
            & > [class*=" col-"] {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    // SAS-79 American Tourister Changes:  making full width background meet new column padding (changes in bootstrapOverrides to container padding)
    .has-background-image {
        position: relative;
        &>.container {
            position: relative;
            .column-background {
                width: calc(100% - calc(#{$spacer*2} * 2)); // doubling these since main containers now have double the outside space
                @include media-breakpoint-up(xl) {
                    width: calc(100% - calc(#{$spacer-xl*2} * 2)); //doubling these since main containers now have double the outside space
                }
            }
        }
        .column-background {
            @include absolute-center;
            height: 100%;
            width: 100%;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            .full-bleed & {
                width: calc(100vw + 2px); // 2px for Chrome bug where you can sometimes see a gap at the browser edges
            }
            &.column-background-tablet,
            &.column-background-desktop {
                display: none;
            }
            @include media-breakpoint-up(md) {
                &.column-background-mobile,
                &.column-background-desktop {
                    display: none;
                }
                &.column-background-tablet {
                    display: block;
                }
            }
            @include media-breakpoint-up(lg) {
                &.column-background-mobile,
                &.column-background-tablet {
                    display: none;
                }
                &.column-background-desktop {
                    display: block;
                }
            }
        }
    }
}

/*
* LAYOUT GRID ASPECT RATIOS
*/
.region-square,
.region-landscape,
.region-portrait {
    position: relative;
    .experience-component {
        position: absolute;
        top: 0;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
        max-width: calc(100% - #{$spacer*2});
        @include media-breakpoint-up(xl) {
            max-width: calc(100% - #{$spacer-xl*2});
        }
    }
}
.region-square {
    @include aspect-ratio($aspect-ratio-square);
}
.region-landscape {
    @include aspect-ratio($aspect-ratio-landscape);
}
.region-portrait {
    @include aspect-ratio($aspect-ratio-portrait);
}
div.sf-interactions-proxyHoverContainer a {
    overflow: visible;
}

// Hover Switch layout component
.hover-switch {
    &--hover {
        display: block;
        opacity: 0;
        visibility: hidden;
        height: 0;
    }
    &:hover {
        .hover-switch {
            &--load {
                display: block;
                opacity: 0;
                visibility: hidden;
                height: 0;
            }
            &--hover {
                display: block;
                opacity: 1;
                visibility: visible;
                height: auto;
            }
        }
    }
}

// Make sure promo tiles on PLP are sized appropriately
.product-grid {
    .experience-region,
    .experience-component,
    .chromeless {
        height: 100%;
    }
    // hero as a promo tile
    .hero {
        min-height: calc(100% - #{$product-tile-grid-margin-bottom});
        margin-bottom: $product-tile-grid-margin-bottom;
    }
}

.folder-nav-layout {
    padding: 0;

    @include media-breakpoint-down(sm) {
        .folder-nav-menu {
            padding: $spacer;
        }

        .folder-based-navigation {
            .card .card-body a {
                &.active {
                    color: $folder-nav-active-link-color;
                    font-weight: $folder-nav-active-link-font-weight;
                }
                + .card {
                    margin-top: $spacer;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .folder-nav-menu {
            width: $folder-nav-desktop-width;
            max-width: $folder-nav-desktop-width;
            background: $folder-nav-desktop-background;
            margin-right: $spacer;
            border-right: $folder-nav-desktop-border-right;
        }

        .folder-nav-content {
            width: calc(100% - #{$folder-nav-desktop-width});
            max-width: calc(100% - #{$folder-nav-desktop-width});
        }

        .folder-based-navigation {
            position: sticky;
            top: calc(#{$header-height-md} + #{$spacer});
            padding-top: $spacer;
            padding-left: $spacer;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - (#{$header-height-md} + #{$header-promo-bar-line-height} + #{$header-promo-bar-padding-top} + #{$header-promo-bar-padding-bottom}));
            .multi-brand & {
                max-height: calc(100vh - (#{$header-height-md} + #{$header-promo-bar-line-height} + #{$header-promo-bar-padding-top} + #{$header-promo-bar-padding-bottom} + #{$pre-nav-height-md}));
            }
            .card {
                margin-bottom: 0;
                border: none;
                .card-header {
                    background: transparent;
                }
                .card-title {
                    @include header($folder-nav-desktop-title-size);
                    text-align: left;
                    padding: 0;
                    margin-bottom: $folder-nav-desktop-title-bottom-margin;
                    cursor: text;
                    word-break: break-word;
                }
                .card-body {
                    padding: 0;
                    margin-bottom: $spacer;
                    background: transparent;
                    a {
                        padding: $folder-nav-desktop-link-padding;
                        text-decoration: none;

                        &.active {
                            color: $folder-nav-active-link-color;
                            font-weight: $folder-nav-active-link-font-weight;
                        }
                        + .card {
                            margin-top: $spacer;
                        }
                    }
                    .card {
                        padding-left: $spacer;
                        border-left: 1px solid $folder-nav-desktop-nested-folder-border-color;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .folder-based-navigation {
            top: calc(#{$header-height-lg} + #{$spacer});
            max-height: calc(100vh - (#{$header-height-lg} + #{$header-promo-bar-line-height} + #{$header-promo-bar-padding-top} + #{$header-promo-bar-padding-bottom}));
            .multi-brand & {
                max-height: calc(100vh - (#{$header-height-lg} + #{$header-promo-bar-line-height} + #{$header-promo-bar-padding-top} + #{$header-promo-bar-padding-bottom} + #{$pre-nav-height-md}));
            }
        }
    }
}
