#checkout-main.data-checkout-stage {
    padding-top: 0px;
    padding-bottom: calc(#{$spacer-xl} * 2);
    @include media-breakpoint-down(sm) {
        padding-left: 0px;
        padding-right: 0px;
    }

    > .row {
        @include media-breakpoint-down(sm) { //no gutters on mobile
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .page-title {
        display: none;
    }

    .alert {
        margin: $spacer;
    }

    .cartAdditionalPaymentButtons {
        padding: $spacer $spacer-xl 0 $spacer-xl;
        @include media-breakpoint-up(md) {
            padding: 0 $spacer-xl;
        }
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        flex-wrap: wrap;
        column-gap: 6px; //matching paypal ootb
        row-gap: calc(#{$spacer-sm} * 2);

        max-width: calc( 750px + #{$spacer-xl * 2}); //capping the width at paypals width so the buttons align correctly

        .js-paymentmethod-mutationobserver {
            flex: 1 1 calc(33% - #{$spacer});
            min-width: 240px; //min-width gpay ootb
            @include media-breakpoint-down(md) {
                &:not(.dual-buttons) {
                    flex-basis: calc(50% - #{$spacer});
                }
            }
            overflow: hidden;
            border-radius: 4px; //matching paypal ootb
            max-height: var(--express-btn-height, 48px);

            [class*="affectedcontainer"] {
                height: 100%;
                max-height: var(--express-btn-height, 48px);
            }

            &:not(:has([class*="button"])):not(:has(button)) {
                display: none;
            }

            &.paypal:has(.paypal-button-number-1),
            &.paypal.dual-buttons { //paypal has 2 buttons number-0 & number-1
                flex-basis: calc(66% - #{$spacer});
                max-width: 750px; // Matching paypals max-width ootb, then centering
                margin: 0 auto;
            }
            #cart-paypal-button-container {
                margin-bottom: 0px;
            }
            .gpay-card-info-container {
                width: 100%;
                height: 100%;
                max-height: var(--express-btn-height, 48px); //Gpay btn is 40px ootb
                .gpay-card-info-animation-container {
                    height: 100%;
                }
                .gpay-card-info-placeholder-container {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    margin: auto;
                    align-items: center;
                }
                .gpay-card-info-iframe {
                    margin-top: calc((var(--express-btn-height, 48px) - 40px) / 2); //Gpay is 40px ootb, this helps center the button content
                }
            }

            .dw-apple-pay-button, .dw-apple-pay-button:hover, .dw-apple-pay-button:active {
                margin: 0px;
                height: 100%;
                max-height:var(--express-btn-height, 48px);
            }
        }
    }

    &[data-selected-payment-method=PayPal],
    &[data-selected-payment-method=DW_GOOGLE_PAY]  {
        .cartAdditionalPaymentButtons {
            display: none;
        }
    }

    .quickbuy-button-divider {
        flex: 10 0 100%;

        &:after {
            content: attr(attr-content);
            position: absolute;
            min-width: calc(#{$spacer} * 2);
            min-height: calc(#{$spacer} * 2);
            background: white;
            left: calc(50% - #{$spacer});
            text-align: center;
            margin-top: calc(#{$spacer} * -2);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 $spacer-sm;
            color: $gray;
        }
    }

    > .row {
        > [class*="col-"] {
            @include media-breakpoint-up(md) {
                padding-top: calc(#{$spacer} * 2);

                &:first-child {
                    border-right: 1px solid $off-white;
                }
            }
        }
    }

    .row {
        [class*="col-"] {
            .card {
                border: none;

                &:not(:first-child) {
                    margin-top: calc(#{$spacer} * 2);
                    @at-root [data-checkout-stage=placeOrder]#{&} {
                        &.customer-section {
                            margin-top: 10px; //aligning with summary column since express btns are hidden
                        }
                    }
                }

                .card-header {
                    margin-bottom: $default-text-font-size;

                    .row [class*="col-"] {
                        display: flex;
                        align-items: center;
                    }

                    h2 {
                        padding-top: 0px;
                        padding-bottom: 0px;
                        background-color: white;
                        z-index: 2;
                    }
                }
                .card-body {
                    padding-bottom: 0px;
                }

                .order-summary-header-container {
                    flex-wrap: nowrap;
                    white-space: nowrap;
                }

                .order-summary-count {
                    padding-top: 0px;
                    padding-bottom: 0px;
                    color: $dark-gray;
                }
            }
            &:last-child {
                .card {
                    margin-top: 0px;
                    .card-body {
                        padding-top: 0px;
                        padding-bottom: 0px;
                        margin-bottom: $spacer;
                    }
                }
            }
        }

        fieldset + fieldset,
        // + .row [class*="col-"],
        + .row:not(.leading-lines):not(.shipment-row) {
            margin-top: calc(#{$spacer});
        }

        .form-group {
            .invalid-feedback {
                + span {
                    margin-bottom: calc(#{$form-help-text-line-height} * -1);
                }
            }
        }

        &.leading-lines {
            margin-bottom: $spacer-sm;
        }
    }

    //Customer Section
    .customer-section {
        .customer-information-block {
            strong {
                display: none;
            }
        }
        .sign-in-right {
            color: $gray;
        }
        .form-group .email + .invalid-feedback {
            //force showing the error field to use dynamic error messaging on keyup
            display: block;
        }
    }

    //Shipping fields
    .show-secondary-address {
        transition: all .2s ease;
        &:not(.collapsed) {
            opacity: 0;
            pointer-events: none;
        }
    }

    //Buttons
    .btn-block {
        max-width: 100%;
        min-height: calc(#{$spacer-xl} * 2);
        margin: 0 auto;
        @include media-breakpoint-up(md) {
            max-width: calc(100% - (#{$spacer-xl} * 2));
        }
    }
    .paypal-button-container {
        max-width: 100%;
    }
    .sticky-checkout-button {
        position: sticky;
        z-index: $z-index-sticky-footer;
        transition: all $transition-speed * 2;

        &.sticky-top {
            top: 0;

            @include media-breakpoint-up(md) {
                .sticky-header.scroll-direction-up & {
                    top: calc(var(--header-height) + 16px);
                }
            }
        }

        &.sticky-bottom {
            bottom: 0;

            @include media-breakpoint-up(md) {
                position: relative;
            }

            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .place-order:not(.loading-logo) {
        &:after {
            content: attr(data-price);
            position: relative;
            opacity: 1;
        }
    }

    //forms
    .form-group {
        margin-top: 0px;

        &.required {
            .form-control-label {
                &:before {
                    display: none;
                }
            }
        }
    }
    .form-control:not(:hover) {
        border-color: $light-gray;
    }

    //shipping methods
    .shipping-method-list {
        .shipment-row {
            padding: $spacer $spacer 0 $spacer;
            border: 1px solid $off-white;

            &.active,
            &:has(input:checked) {
                background-color: $off-white;
                border: 1px solid $brand-primary;
                z-index: 1;
                position: relative;
            }

            .card-header,
            .card-body {
                background-color: transparent;
                padding-left: 0px;
                padding-right: 0px;
            }

            .start-lines,
            .end-lines {
                > div {
                    display: flex;
                    flex-direction: row;
                    .shipping-method-option {
                        display: flex;
                        flex-direction: column;
                        padding-right: $spacer-sm;
                    }
                }
                label, span {
                    background-color: transparent;
                }
            }

            + .shipment-row {
                margin-top: -1px;
            }

            &:first-of-type {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
            &:last-of-type {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }

    //payment section
    .accordion.payment-information {
        .card {
            border: 1px solid $off-white;

            &.active {
                background-color: $off-white;
                border: 1px solid $brand-primary;
                z-index: 1;
                position: relative;

                .card-header:not(:has(.collapsed)) {
                    border-bottom: 1px solid $brand-primary;
                }
            }

            .card-header {
                background-color: transparent;
                cursor: pointer;
                padding-left: $spacer-xl;
                .custom-control {
                    padding: $spacer;
                }
                .payment-drawer-image-icon {
                    .nav-item {
                        list-style: none;
                        margin-bottom: 0px;
                        .nav-link {
                            cursor: default;
                            user-select: none;
                            pointer-events: none;
                            margin: 0px;
                            padding: 0px;
                            right: 0;
                        }
                    }
                }
            }
            .card-body {
                background-color: transparent;
                padding-top: 0px;
                padding: $spacer;
            }

            img {
                max-height: $spacer-xl;
            }

            + .card {
                margin-top: -1px;
            }

            &:first-of-type {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
            &:last-of-type {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }

            &[data-method-id="DW_APPLE_PAY"]:has(isapplepay:empty),
            &[data-method-id="DW_GOOGLE_PAY"]:not(:has(iframe)) {
                display: none;
            }
            &[data-method-id="DW_GOOGLE_PAY"],
            &[data-method-id="PayPal"] {
                .summary-section-label,
                .summary-details {
                    margin-top: $spacer-sm;
                    color: $dark-gray;
                    font-size: $font-size-sm;
                    mix-blend-mode: multiply;
                }
                .paymentSummaryInfo {
                    display: none; //paypal keeps updating this to the original price before shipping changes
                }
            }
        }
    }

    &[data-selected-payment-method=PayPal],
    &[data-selected-payment-method=DW_GOOGLE_PAY] {
        .accordion.payment-information .card {
            display: none;
        }
    }

    &[data-selected-payment-method=PayPal] {
        .accordion.payment-information .card[data-method-id=PayPal] {
            display: block;
        }
    }

    &[data-selected-payment-method=DW_GOOGLE_PAY] {
        .accordion.payment-information .card[data-method-id=DW_GOOGLE_PAY] {
            display: block;
        }
    }

    //Summary Section
    .accordion#order-product-summary {
        button.order-summary-header-container {
            padding-bottom: $spacer-xl;
            > [class*="col"]:first-child {
                @include icon($accordion-header-icon-close, after, $accordion-header-icon-size, $accordion-header-icon-color);
            }
            &.collapsed {
                > [class*="col"]:first-child {
                    @include icon($accordion-header-icon-expand, after, $accordion-header-icon-size, $accordion-header-icon-color);
                }
            }
        }
        .card-body.collapse {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
    .order-product-summary {
        .product-summary-block {
            .item-attributes {
                display: flex;
                overflow: hidden;
                flex: 1 0;

                > * {
                    &:first-child {
                        flex-grow: 1;
                        overflow: hidden;
                    }
                    &:last-child {
                        align-self: flex-start;
                        margin-top: 0px;
                        line-height: 1.3; //matching line 73 in productCard
                    }
                }

                .line-item-header {
                    margin-bottom: 2px;
                }

                .line-item-attributes,
                .line-item-name {
                    line-height: $spacer;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    font-size: inherit;

                    &.each-price {
                        //price model passed in on order confirmation page does not have this attribute
                        [data-action="Order-Confirm"] & {
                            display: none;
                        }
                    }
                }
            }
            .item-image {
                width: calc(clamp(40px, 16%, 75px));
            }
        }
    }
    .accordion-cart-promo {
        .accordion {
            width: 100%;

            .card {
                margin-top: 0px;
                .card-header {
                    //margin-bottom: 0px;
                    padding: 0px;
                }
            }

            .title {
                display: flex;
                justify-content: space-between;
                h2 {
                    padding: 0px;
                }
            }

            .promo-code-submit {
                min-width: 35%;
            }

            .promo-code-btn {
                min-width: 100%;
            }
        }
    }
    .order-total-summary {
        margin-top: $spacer;

        .grand-total {
            display: none;
        }
    }

}

.customer-information-block {
    .submit-customer {
        margin-top: 10px;
    }
    .btn-block+.btn-block {
        margin-top: 10px;
    }
    .password-form {
        margin-bottom: 20px;
    }
}

.data-checkout-stage {
    .row > .col-12 .text-center {
        margin-bottom: 10px;
    }
    .row [class*='col-'] .card {
        margin-bottom: 0;
        &~.card {
            .card-header {
                border-top: 0;
            }
            &.customer-section,
            &.customer-summary {
                border-top: 1px solid $card-header-border-color;
                .summary-section-label + br { //hiding the html break thrown in by sfra
                    display: none;
                }
            }
            &.ghost.payment {
                border: solid $card-header-border-color;
                border-width: 0 0 1px 0;
            }
        }

    }
    .card-header {
        .edit-button {
            @include text-link;
            color: $card-header-color;
            &:hover {
                color: $card-header-color;
            }
        }
    }
    .shipping-summary,
    .payment-summary {
        .leading-lines {
            margin-bottom: 0;
        }
        .summary-details {
            padding-bottom: $checkout-spacer;
            .address-summary,
            .shipping-phone,
            .shipping-method,
            .gift-message-summary,
            .order-summary-email,
            .order-summary-phone,
            .payment-details {
                color: $card-form-value-color;
                margin-bottom: 1px;
            }
        }
    }
    .payment-summary {
        .addressInformation.hideAddressInformationForPayment {
            display: none;
        }
    }

    .billing {
        .order-summary-email {
            display: none;
        }
    }

    .view-address-block,
    .shipment-block,
    .product-summary-block {
        h3:not(:first-child),
        .multi-shipping {
            margin-top: $spacer;
        }
        address,
        .store-details,
        .shipping-header-text + p {
            margin-left: $spacer;
            address {
                margin-left: 0;
            }
        }
    }
    .shipping-method-block {
        @include inverse-internal-spinner;
        h3 {
            margin-bottom: $spacer;
        }
        .shipping-method-list {
            .start-lines {
                margin-bottom: 12px;
                &:before {
                    left: $spacer-xl+$radiocheck-button-size+$radiocheck-button-margin;
                }
            }
            .end-lines {
                margin-bottom: 12px;
            }
        }
        span.display-name {
            padding-right: 0;
            line-height: calc(#{$spacer} * 2);
            margin-top: calc(#{$spacer} / -2);
        }
        .text-muted.arrival-time {
            padding-top: 3px;
            margin-top: -3px;
            top: -1px;
            padding-left: 3px;
            left: -3px;
            padding-right: 0;
        }
        .shipping-cost {
            float: right;
            font-size: inherit;
        }
    }
    .gift-message-block {
        display: none;
        margin-bottom: $spacer;
        .gift-message {
            margin-top: $spacer;
        }
    }
    .gift-message-summary {
        margin-bottom: $spacer;
    }
    .shipment-selector-block,
    .address-selector-block {
        .btn-show-details,
        .btn-add-new {
            @include text-link;
            margin-top: $spacer-xl;
            padding: 0;
            border: none;
        }
    }
    .contact-info-block {
        .info-icon {
            font-size: $form-label-font-size;
        }
    }
    .giftcertificate-information {
        margin-top: $spacer;
        .applybalancebutton {
            margin-top: auto;
            margin-bottom: $form-help-text-margin-top;
        }
    }
    .payment-information {
        .payment-options {
            .nav-item {
                margin-bottom: $spacer-xl;
                display: flex;
                &:only-child {
                    display: none;
                }
                &:not(:only-child) {
                    margin-top: calc(#{$form-group-margin-top} - #{$form-help-text-margin-top});
                }
                > .nav-link {
                    width: 100%;
                }
            }
        }
    }
    .form-control.shippingZipCode~span,
    .form-control.shippingPhoneNumber~span,
    .form-control.billingZipCode~span {
        font-size: $form-help-text-font-size;
        line-height: $form-help-text-line-height;
        letter-spacing: $form-help-text-letter-spacing;
        margin-top: 4px;
        display: block;
        &:not(.invalid-feedback) {
            color: $form-help-text-color;
        }
    }
    .credit-card-selection-new,
    .user-payment-instruments {
        .info-icon {
            font-size: $form-label-font-size;
        }
        .paypal-content {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
        .saved-payment-information {
            margin-top: $spacer-xl/2;
            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }
        .card-image {
            max-width: 100%;
        }
        .saved-payment-instrument {
            padding-top: $spacer-xl/2;
            padding-bottom: $spacer-xl/2;
            align-items: center;
            cursor: pointer;

            &.selected-payment {
                background-color: $checkout-selected-payment-background;
                border: $checkout-selected-payment-border;
                color: $checkout-selected-payment-color;
            }
        }
        .add-payment {
            margin-top: $spacer-xl/2;
        }
        .cancel-new-payment {
            margin-top: $spacer-xl;
        }
        .selected-payment {
            background-color: $checkout-selected-payment-background;
            border: $checkout-selected-payment-border;
            color: $checkout-selected-payment-color;
        }
        .save-credit-card {
            margin-top: 5px;
        }
    }
    .credit-card-form {
        select:invalid {
            color: $input-placeholder-color;
        }
    }

    // mobile footer
    .next-step-button {
        margin-top: $spacer-xl;
        @include media-breakpoint-down(sm) {
            background-color: $checkout-mobile-footer-background;
            box-shadow: $checkout-mobile-footer-box-shadow;
            z-index: $z-index-sticky-footer;
            .btn {
                margin-top: $spacer-xl;
                margin-bottom: $spacer-xl;
            }
        }
        .card {
            border: 0;
        }

        > div {
            display: flex;
            flex-direction: column-reverse;
        }
        .place-order {
            + .place-order-disclosure-text {
                display: flex;
                width: 100%;
                @include media-breakpoint-up(md) {
                    width: calc(100% - (#{$spacer-xl} * 2));
                }
                margin: 0 auto;
                > div {
                    margin: 0px;
                    margin-top: $spacer;
                    @include media-breakpoint-up(md) {
                        margin-top: 0px;
                        margin-bottom: $spacer;
                    }
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .checkout-progress {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        flex-wrap: nowrap;
        margin: 0;
        margin-bottom: -1px;
        position: relative;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            margin-left: $spacer-xl;
            width: calc(100% - #{$spacer-xl});
        }

        > [class*="summary"][data-stage] {
            overflow: hidden;
            transition: all $transition-speed * 3;
            padding-top: $spacer;
            flex: 0 1 auto;
            mask-image: linear-gradient(90deg, white 85%, transparent 100%);
            @include media-breakpoint-up(md) {
                mask-image: linear-gradient(90deg, white 95%, transparent 100%);
            }
            min-width: $checkout-step-number-width;
            max-width: calc(100% - #{$checkout-step-number-width * 2}); //2 widths to always show the step numbers
            margin-right: calc(min(2vw, #{$spacer}));
            &:last-child {
                margin-right: 0;
            }
        }

        &:hover {
            > [class*="summary"][data-stage] {
                flex-shrink: 1;
                .checkout-progress-section {
                    max-width: 100%;
                    overflow: hidden;
                }
                &:hover {
                    flex-grow: unset;
                    flex-shrink: 0;
                }
            }
        }

        .checkout-progress-section {
            display: flex;
            align-items: center;
            color: map-get($content, secondary);
            @include headerOrBanner($checkout-step-header-or-banner, $checkout-step-header-value);
            padding-bottom: $spacer-sm * 2;
            padding-right: $spacer;
            border-bottom: 1px solid transparent;
            @include disabled();
            white-space: nowrap;

            .number {
                color: $white;
                background-color: map-get($content, secondary);
                border-radius: $spacer-xl;
                width: $checkout-step-number-width;
                min-width: $checkout-step-number-width;
                height: $checkout-step-number-width;
                font-size: $checkout-step-number-font-size;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: $spacer-sm * 2;
                line-height: normal;
            }
        }
    }
    @mixin active-checkout-progress-section {
        cursor: pointer;
        color: $brand-primary;
        border-bottom: 1px solid $brand-primary;
        z-index: 1;
        opacity: 1;
        pointer-events: auto;
        .number {
            background-color: $brand-primary;
        }
    }

    /*
    * Show/Hide behavior for various checkout states
    */
    // Initial states ------------------------
    [data-customer-type=guest] .single-shipping .shipment-selector-block,
    //option[value=new],
    .single-shipping .shipping-form:not(.in-store-pickup) .multi-ship-action-buttons,
    .single-shipping .view-address-block,
    .multi-ship .single-shipping .shipping-content,
    .multi-ship .shipping-summary .single-shipping,
    .multi-ship .confirm-details .single-shipping,
    .multi-shipping,
    .error-message,
    .checkout-hidden {
        display: none;
    }
    &.multi-ship .multi-shipping,
    span.ship-to-name,
    span.ship-to-address1,
    span.ship-to-address2,
    span.ship-to-phone,
    span.ship-to-city-st-zip {
        display: block;
    }
    &[data-checkout-stage] {
        .card.payment-summary,
        .shipping-summary,
        button.submit-payment,
        button.submit-shipping {
            display: none;
        }
        // Cybersource customizations
        .card.secure-acceptance-iframe,
        button.sa_redirect,
        button.alipay, button.sof, button.eps, button.idl, button.gpy, button.mch, button.klarna,
        button.sa_iframe,
        button.sa_silentpost,
        button.sa_flex {
            display: none;
        }
    }

    // Customer ------------------------------
    &[data-checkout-stage=customer] {
        .shipping-address-block {
            > .row .form-group {
                margin-top: calc(#{$form-group-margin-top} - #{$form-help-text-margin-top});
            }
            &:not(.d-none) + .shipping-method-block {
                margin-top: $checkout-spacer;
            }
        }

        &.multi-ship .order-product-summary,
        .card.ghost.customer,
        //.card.customer-summary,
        //.card.payment-form,
        .card.ghost.shipping {
            display: none;
        }

        button.submit-customer,
        button.submit-shipping {
            // display: block;
        }

        .checkout-progress {
            [class*="summary"][data-stage="shipping"] {
                flex-shrink: 0;
            }
            &:hover {
                [class*="summary"][data-stage="shipping"] {
                    flex-shrink: 1;
                    &:hover {
                        flex-shrink: 0;
                    }
                }
            }
            .checkout-progress-section[data-stage="shipping"] {
                @include active-checkout-progress-section();
            }
        }
    }

    // Shipping ------------------------------
    &[data-checkout-stage=shipping] {
        //.card.customer-summary,
        button.submit-customer,
        .card.ghost.customer,
        &.multi-ship .order-product-summary,
        //.card.payment-form,
        .card.ghost,
        .view-address-block,
        [data-address-mode=customer] .shipping-address-block,
        [data-address-mode=shipment] .shipping-address-form,
        [data-address-mode=edit] .shipping-address-block,
        [data-address-mode=new] .btn-show-details,
        [data-address-mode=new] .btn-add-new {
            display: none;
        }

        .shipping-address-block {
            > .row .form-group {
                margin-top: calc(#{$form-group-margin-top} - #{$form-help-text-margin-top});
            }
            &:not(.d-none) + .shipping-method-block {
                margin-top: $checkout-spacer;
            }
        }

        .multi-shipping .card {
            .card-header {
                border-top: 0;
            }
            .card-title {
                border-bottom: 0;
            }
        }

        .checkout-progress {
            [class*="summary"][data-stage="shipping"] {
                flex-shrink: 0;
            }
            &:hover {
                [class*="summary"][data-stage="shipping"] {
                    flex-shrink: 1;
                    &:hover {
                        flex-shrink: 0;
                    }
                }
            }
            .checkout-progress-section {
                &[data-stage="shipping"] {
                    @include active-checkout-progress-section();
                }
            }
        }
    }

    // Payment -------------------------------
    &[data-checkout-stage=payment] {
        button.submit-customer,
        //.card.customer-summary,
        .card.ghost,
        [data-address-mode=new] .btn-show-details,
        [data-address-mode=new] .btn-add-new,
        [data-address-mode=details] .btn-show-details,
        [data-address-mode=details] .btn-add-new,
        [data-address-mode=customer] .shipping-address-block,
        [data-address-mode=edit] .shipping-address-block,
        [data-address-mode=shipment] .address-selector-block + .billing-address,
        [data-address-mode=edit] .address-selector-block + .billing-address,
        button#hideSubmitPayment,
        .card.secure-acceptance-iframe,
        button.sa_redirect,
        button.sa_iframe,
        button.alipay, button.sof, button.eps, button.idl, button.gpy, button.mch, button.klarna,
        button.sa_silentpost,
        button.sa_flex {
            display: none;
        }
        .card.payment-form,
        button.submit-payment,
        button.credit_card,
        button.credit_card.place-order,
        button#showSubmitPayment {
            display: block;
        }
        .contact-info-block,
        .billing-address,
        .billingAddressFields,
        .credit-card-form {
            > .row .form-group {
                margin-top: calc(#{$form-group-margin-top} - #{$form-help-text-margin-top});
            }
        }

        .credit-card-form {
            .form-control-label {
                @include media-breakpoint-only(md) {
                    font-size: calc(#{$default-text-font-size} - 2px);
                }
            }
        }

        .checkout-progress {
            [class*="summary"][data-stage="billing"] {
                flex-shrink: 0;
            }
            &:hover {
                [class*="summary"][data-stage="billing"] {
                    flex-shrink: 1;
                    &:hover {
                        flex-shrink: 0;
                    }
                }
            }
            .checkout-progress-section {
                &[data-stage="shipping"],
                &[data-stage="billing"] {
                    cursor: pointer;
                    opacity: 1;
                    pointer-events: auto;
                }
                &[data-stage="billing"] {
                    @include active-checkout-progress-section();
                }
            }
        }
    }

    // Place Order -----------------------------
    &[data-checkout-stage=placeOrder] {
        button.submit-customer,
        .card.customer-summary,
        .card.shipping-summary,
        .card.payment-summary,
        .card.ghost,
        .quickbuy-button-divider,
        button.sasilentpost,
        .card.secure-acceptance-iframe {
            .page & {
                display: none;
            }
        }

        button.place-order,
        .card.secure-acceptance-iframe,
        button.sa_redirect,
        button.sa_iframe,
        button.sa_silentpost,
        button.alipay, button.sof, button.eps, button.idl, button.gpy, button.mch, button.klarna, button.credit_card,
        button.sa_flex {
            display: block;
        }

        .checkout-button-wrapper {
            display: flex;
            flex-direction: column-reverse;

            .place-order {
                + .place-order-disclosure-text {
                    display: flex;
                    width: calc(100% - (#{$spacer-xl} * 2));
                    margin: 0 auto;
                    > div {
                        margin: 0px;
                        margin-top: $spacer;
                        @include media-breakpoint-up(md) {
                            margin-top: 0px;
                            margin-bottom: $spacer;
                        }
                        p {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }

        .checkout-progress {
            [class*="summary"][data-stage="review"] {
                flex-shrink: 0;
            }
            &:hover {
                [class*="summary"][data-stage="review"] {
                    flex-shrink: 1;
                    &:hover {
                        flex-shrink: 0;
                    }
                }
            }
            .checkout-progress-section {
                &[data-stage="shipping"],
                &[data-stage="billing"],
                &[data-stage="review"] {
                    cursor: pointer;
                    opacity: 1;
                    pointer-events: auto;
                }
                &[data-stage="review"] {
                    @include active-checkout-progress-section();
                }
            }
        }
    }

    // Submitted -----------------------------
    &[data-checkout-stage=submitted] {
        .shipping-form,
        .card.payment-form,
        button.submit-customer,
        .customer-section,
        .summary-section-label.customer-label,
        .card.ghost,
        .summary-section-label.shipping-addr-label,
        button.place-order,
        button.credit_card,
        .checkout-progress {
            display: none;
        }
        .card.payment-summary,
        .shipping-summary {
            display: block;
        }
    }

    .coupon-price-adjustment {
        margin-top: $spacer;
        background-color: $card-body-background;
        border: 1px solid $card-body-border-color;
        border-radius: $card-border-radius;
        padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
        font-size: $input-font-size-md;
        line-height: $input-font-line-height;
        letter-spacing: $input-font-letter-spacing;
        position: relative;
        .coupon-applied,
        .coupon-not-applied {
            @include paragraph-font-size(small);
        }
        .coupon-applied {
            color: $success;
        }
        .coupon-not-applied {
            color: $danger;
        }
        .remove-coupon {
            @include delete-card-button;
            border: 0px;
            margin: 0;
            height: auto;
            width: auto;
            position: unset;
        }
        .coupon-promotion-relationship {
            @include paragraph-font-size(small);
            margin-bottom: 0;
        }
    }
    .coupon-missing-error {
        display: none;
    }
}

/*
* Shared styles for account order detail and checkout confirmation pages
*/
.data-checkout-stage,
.receipt,
.account-page {
    // totals
    .order-total-summary,
    .order-product-summary {
        z-index: 1;
        .grand-total,
        .grand-total .order-receipt-label,
        .grand-total-sum {
            font-family: $checkout-totals-font-family;
            font-size: $checkout-totals-font-size;
            font-weight: $checkout-totals-font-weight;
            text-transform: $checkout-totals-text-transform;
        }
        .leading-lines {
            .start-lines,
            .end-lines {
                background: transparent;
                mix-blend-mode: multiply;
            }
        }
        .item-image {
            mix-blend-mode: multiply;
        }
    }
    .summary-section-label,
    .shipping-section h3,
    .product-summary-block .shipment-block h3,
    .product-summary-block .multi-shipping h3 {
        @include label-styles;
        display: block;
        color: $card-header-color;
    }
    .order-total-summary {
        .grand-total {
            border-top: 1px solid $card-body-border-color;
            padding-top: $spacer-xl;
            margin-bottom: $spacer-sm;
        }
        .shipping-selected-method-sub-text {
            @at-root .multi-ship#{&} {
                display: none;
            }
        }
        .start-lines, .end-lines {
            align-self: flex-start;
            .shipping-cost {
                padding-right: 0;
            }
        }
    }
    // Cart Items
    .order-product-summary {
        .product-summary-block {}
        .card-header {
            border-bottom: 1px solid $card-header-border-color;
            padding: $spacer-xl $card-header-padding-right $spacer-xl $card-header-padding-left;
        }
        .leading-lines {
            margin-bottom: 0;
        }
        .product-line-item {
            .line-item-name,
            .line-item-pricing-info {
                text-decoration: none;
                padding: 0px;
            }
            .item-attributes {
                padding: 0;
                & .line-item-attributes,
                & .item-options {
                    color: $card-form-value-color;
                    margin-top: 1px;
                    text-overflow: $cart-product-name-text-overflow;
                    white-space: $cart-product-name-white-space;
                    overflow: hidden;
                    width: 100%;
                }
            }
            .item-price-qty {
                border-top: none;
                padding-top: $spacer-xl;
                > .col {
                    padding: 0;
                }
            }
            .line-item-total-text {
                color: $card-form-value-color;
            }
            .line-item-total-price-amount {
                color: $black;
            }
            &~.store-details {
                margin-left: $spacer;
                margin-bottom: $spacer;
            }
            &~address {
                margin-left: $spacer;
                span {
                    display: block;
                }
            }
        }
    }
    .js-shipping-line-items {
        padding-top: $spacer;
        h5 {
            @include header(6);
            margin-top: $spacer;
        }
    }

    // optin widget
    .OptinWidget {
        .optin_info {
            .optin_title.customizable {
                color: map-get($content, primary) !important;
            }
            .optin_description.customizable {
                color: map-get($content, primary) !important;
            }
        }
    }

    // confirmation page
    .confirm-details {
        .summary-section-label {
            @include label-styles;
            display: block;
            color: $card-header-color;
            font-weight: bold;
        }
        .shipping-method-label {
            margin-top: $spacer;
        }
        .shipping-method-arrival-time {
            @include paragraph-font-size(small);
            padding-top: 3px;
            margin-top: -3px;
            top: -1px;
            padding-left: 3px;
            left: -3px;
        }
        .summary-details,
        .shipping-method,
        .shipping-method-title,
        .pricing {
            color: $card-form-value-color;
        }
        .card-body > .summary-details:last-child {
            padding-bottom: $checkout-spacer;
        }

        .shipping-items {
            flex-direction: column;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
        }
    }
}
.receipt,
.account-page {
    // Hide overflow for background :after element
    overflow-x: hidden;

    .background-color-column {
        background: transparent;

        &:after {
            content: '';
            position: absolute;
            top: 0px;
            background: $off-white;
            height: calc(100vh - var(--full-header-height) - #{$footer-margin-top} - #{$footer-padding-top}); // Ensure the background doesn't go beyond the footer
            width: 100vw;
            z-index: 0;
            left: 0vw;
            border-left: 1px solid $card-body-border-color;
            mix-blend-mode: multiply;
            pointer-events: none;
        }
    }

    .confirm-details {
        margin-bottom: 0;
    }
}
.receipt {
    .checkout-order-total-summary .order-total-summary {
        border-top: none;
    }

    .order-product-summary {
        padding-bottom: $spacer;
        .card-body {
            padding: 0;
        }
        .product-summary-block {
            .item-attributes {
                display: flex;
                overflow: hidden;
                flex: 1 0;

                > * {
                    &:first-child {
                        flex-grow: 1;
                        overflow: hidden;
                    }
                    &:last-child {
                        align-self: flex-start;
                        margin-top: 0px;
                        line-height: 1.3; //matching line 73 in productCard
                    }
                }

                .line-item-header {
                    margin-bottom: 2px;
                }

                .line-item-attributes,
                .line-item-name {
                    line-height: $spacer;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    font-size: inherit;

                    &.each-price {
                        //price model passed in on order confirmation page does not have this attribute
                        [data-action="Order-Confirm"] & {
                            display: none;
                        }
                    }
                }
            }
            .item-image {
                width: calc(clamp(40px, 16%, 75px));
            }
        }
    }

    .confirm-details {
        .order-date-label,
        .order-date {
            display: none;
        }

        .payment-details > div {
            display: block;
        }
    }

    .order-confirm-create-account {
        background-color: $off-white;
        border-top: 0px;
         > [class*="card"],
         .card-body {
            background: transparent;
        }
    }
}
.page[data-action="Order-Confirm"]:has(.receipt) {
    overflow-x: hidden;
}

// hides google places form dropdown styles that stick around
.pac-container:empty {
    box-shadow: none;
    border-top: 0;
}

.shipping-total-cost,
.shipping-lineitem-cost,
.shipping-method-price,
.shipping-cost {
    &[data-cost*="Free"],
    &[data-cost*="free"] {
        color: $green;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: .03em;
    }
}


// optin widget modal
.SmsView .dialog_title.customizable {
    color: map-get($content, primary) !important;
}


#deliveryAddressVerificationModal .modal-dialog .modal-body {
    .highlight {
        background-color: map-get($bg, warning);
    }

    .dav-buttons-div .useOrigAddress {
        width: 100%;
        margin-left: 0;
    }

    .show-secondary-address {
        opacity: 0;
        pointer-events: none;
    }

}

.product-summary-block {
    .item-attributes {
        .line-item-attributes {
            &.each-price {
                //price model passed in on order confirmation page does not have this attribute
                [data-action="Order-Confirm"] & {
                    display: none;
                }
            }
        }
    }
}

//paypal button specific styles
#iframe {
    #buttons-container {
        .paypal-button-container {
            max-width: 100% !important;
        }
    }
}
