.toast-messages {
    @include toast-message;
    .alert {
        @include toast-alert;
    }
}

$alerts: success, warning, danger, info;

.alert {
    border-radius: $alert-border-radius;
    padding: $alert-padding;
    border-style: solid;
    border-top-width: nth($alert-border-width, 1);
    border-right-width: nth($alert-border-width, 2);
    border-bottom-width: nth($alert-border-width, 3);
    border-left-width: nth($alert-border-width, 4);

    @each $type in $alerts {
        &#{"-" + $type} {
            background: map-get($bg, #{$type});
            border-color: map-get($border, #{$type});
            color: map-get($content, #{$type});
            a, a:hover {
                color: darken(map-get($content, #{$type}), 10%);
            }
            .close {
                color: map-get($content, #{$type});
            }
        }
    }

    &-dismissible {
        padding-right: calc(#{$spacer * 2} + 12px);
    }
    .close {
        padding: $spacer;
        line-height: $base-font-size + 10px - 4px;
        &:before {
            position: static;
            transform: none;
            margin: 0;
            font-size: 12px;
        }
    }
}