@import "~core/components/hero";

.hero-hr {
    border-width: $hero-hr-thickness;
    border-style: solid;
    width: calc(100% + (#{$spacer * 2}));
    @include media-breakpoint-up(md) {
        width: calc(100% + (#{$hero-hr-overhang} * 2));
    }
    left: calc(#{$hero-hr-overhang} * -1);
    position: relative;
    border-color: inherit;

    .dark-theme & {
        border-color: $white;
    }
    .light-theme & {
        border-color: $black;
    }
}

.hero-text {
    &.hr-after-title {
        // text widths
        @include media-breakpoint-up(md) {
            &.width-1-4 {
                max-width: calc(25% - (#{$hero-hr-overhang}));
            }
            &.width-1-3 {
                max-width: calc(33.3333% - (#{$hero-hr-overhang}));
            }
            &.width-1-2 {
                max-width: calc(50% - (#{$hero-hr-overhang}));
            }
            &.width-2-3 {
                max-width: calc(66.6666% - (#{$hero-hr-overhang}));
            }
            &.width-3-4 {
                max-width: calc(75% - (#{$hero-hr-overhang}));
            }
            &.width-1 {
                max-width: calc(100% - (#{$hero-hr-overhang}));
            }
        }
    }
    .hero-buttons {
        .hero-button {
            z-index: 1;
        }
    }
}

.hero {
    .hero-container {
        &.tight-padding {
            padding: $spacer-sm;
            @include media-breakpoint-up(sm) {
                padding: $spacer;
            }
        }
    }
    &.align-items-start,
    &.align-items-md-start {
        &:not(.auto-size-for-image) {
            .top-hero {
                padding-top: calc(var(--header-height, #{$header-height}) + #{$hero-textblock-outer-padding});
                @include media-breakpoint-up(md) {
                    padding-top: calc(var(--header-height, #{$header-height-md}) + #{$hero-textblock-outer-padding-md});
                }
                @include media-breakpoint-up(lg) {
                    padding-top: calc(var(--header-height, #{$header-height-lg}) + #{$hero-textblock-outer-padding-lg});
                }
            }
            //both above and below can be applied but shouldn't be applied together
            //below automates header padding away from transparent header
            .transparent-header + #maincontent > .page-designer > .experience-region:first-child > .experience-component:first-child & {
                padding-top: calc(var(--header-height, #{$header-height}));
                @include media-breakpoint-up(md) {
                    padding-top: calc(var(--header-height, #{$header-height-md}));
                }
                @include media-breakpoint-up(lg) {
                    padding-top: calc(var(--header-height, #{$header-height-lg}));
                }
                .top-hero { //resetting this padding incase both of these styles are being applied
                    padding: $hero-textblock-outer-padding;
                    @include media-breakpoint-up(md) {
                        padding: $hero-textblock-outer-padding-md;
                    }
                    @include media-breakpoint-up(lg) {
                        padding: $hero-textblock-outer-padding-lg;
                    }
                }
            }
        }
    }
    .embedded-image-tile {
        width: $hero-embedded-image-width;
        border-radius: $hero-embedded-image-border-radius;
        margin: 0 auto;
    }
}

.hero.half-and-half {
    @include media-breakpoint-down(sm) {
        &.short {
            min-height: 0;
            .hero-media {
                height: calc(#{$hero-min-height-short} / 2);
            }
        }
        &.medium {
            min-height: 0;
            .hero-media {
                height: calc(#{$hero-min-height-medium}  / 2);
            }
        }
        &.tall,
        &.screen-height {
            min-height: 0;
            .hero-media {
                height: calc(#{$hero-min-height-tall}  / 2);
            }
        }
    }
}
.hero.rounded-accent {
    border-radius: 0px 0px 55px 0px;
}

// new Auto-sizing For image option for hero
@mixin reset-padding() {
    padding: 0px;
    @include media-breakpoint-up(sm) { padding: 0px; }
    @include media-breakpoint-up(md) { padding: 0px; }
    @include media-breakpoint-up(lg) { padding: 0px; }
}
@mixin hero-text-padding() {
    padding: $hero-textblock-outer-padding;
    padding-left: $hero-textblock-outer-padding;
    padding-right: $hero-textblock-outer-padding;
    @include media-breakpoint-up(md) {
        padding: $hero-textblock-outer-padding-md;
        padding-left: $hero-textblock-outer-padding-md;
        padding-right: $hero-textblock-outer-padding-md;
    }
    @include media-breakpoint-up(lg) {
        padding: $hero-textblock-outer-padding-lg;
        padding-left: $hero-textblock-outer-padding-lg;
        padding-right: $hero-textblock-outer-padding-lg;
    }
}
@mixin half-half-hero-width($x: 1) {
    width: 100%;
    max-width: calc(50cqw * #{$x}); //Default width
    transform: none;
    left: unset;
    right: unset;
}
@mixin max-widths-with-full-bleed($mw: 1/2, $x: '') {
    max-width: calc(#{$mw} * 100cqw);
    @if $x { transform: none; }
    .full-bleed & {
        width: calc(#{$mw} * 100vw);
        max-width: calc(#{$mw} * 100vw);
        @if $x { transform: translateX(calc(((#{$mw} * 100vw) - (#{$mw} * 100cqw)) * #{$x})); }
    }
}
@mixin auto-size-hero-image-n-text-widths($x: 1) {
    picture {
        img {
            @include max-widths-with-full-bleed(1/2, $x);
            &.width-1-4 { @include max-widths-with-full-bleed(3/4, $x); }
            &.width-1-3 { @include max-widths-with-full-bleed(2/3, $x); }
            &.width-1-2 { @include max-widths-with-full-bleed(1/2, $x); }
            &.width-2-3 { @include max-widths-with-full-bleed(1/3, $x); }
            &.width-3-4 { @include max-widths-with-full-bleed(1/4, $x); }
        }
        + .hero-text {
            @include max-widths-with-full-bleed(1/2, null);
            &.width-1-4 { @include max-widths-with-full-bleed(1/4, null); }
            &.width-1-3 { @include max-widths-with-full-bleed(1/3, null); }
            &.width-1-2 { @include max-widths-with-full-bleed(1/2, null); }
            &.width-2-3 { @include max-widths-with-full-bleed(2/3, null); }
            &.width-3-4 { @include max-widths-with-full-bleed(3/4, null); }
        }
    }
}

.hero.auto-size-for-image {
    container-type: inline-size;
    .hero-container {
        container-type: inline-size;
        height: auto;
        @include reset-padding();
        &.tight-padding {
            @include reset-padding();
            .hero-text {
                padding: $spacer-sm;
                padding-left: $spacer-sm;
                padding-right: $spacer-sm;
                @include media-breakpoint-up(sm) {
                    padding: $spacer;
                    padding-left: $spacer;
                    padding-right: $spacer;
                }
            }
        }
        .hero-text { @include hero-text-padding(); }
    }
    &.half-and-half {
        picture img.hero-media {
            @include half-half-hero-width();
            position: relative;
            margin: 0;
        }
        .flex-column, .flex-md-column {
            &[class*="justify-content-md"] .hero-text { @include hero-text-padding(); }
        }
        &.justify-content-start, &.justify-content-center {
            .hero-container.flex-row {
            @include media-breakpoint-down(sm) {
                flex-direction: row-reverse !important;
                @include auto-size-hero-image-n-text-widths(1);
            } //override the hero-stacking stuff
        }
        }
        &.justify-content-end .hero-container.flex-row {
            @include media-breakpoint-down(sm) {
                flex-direction: row !important;
                @include auto-size-hero-image-n-text-widths(-1);
            } //override the hero-stacking stuff
        }
        &.align-items-center .hero-container .hero-text { justify-content: center; }
        &.align-items-end .hero-container .hero-text { justify-content: end; }

        .hero-container {
            max-width: 100vw;
            &.flex-column {
                @include media-breakpoint-down(sm) {
                    .hero-text, .hero-media { @include half-half-hero-width(2); }
                }
            }
            &.flex-md-column {
                @include media-breakpoint-only(md) {
                    .hero-text, .hero-media { @include half-half-hero-width(2); }
                }
            }
            &[class*="justify-content-md"] {
                @include media-breakpoint-up(md) {
                    &.flex-lg-row {
                        &:not(.flex-md-column) { // if hero half and half is set to text below on tablet: don't use these values
                            @include media-breakpoint-only(md) {
                                flex-direction: row-reverse !important; //put the image on the correct side, not using absolute positioning anymore
                                @include auto-size-hero-image-n-text-widths(1);
                            }
                        }
                        @include media-breakpoint-up(lg) {
                            flex-direction: row-reverse !important; //put the image on the correct side, not using absolute positioning anymore
                            @include auto-size-hero-image-n-text-widths(1);
                        }
                    }
                }
            }
            &.justify-content-md-end {
                @include media-breakpoint-up(md) {
                    &.flex-lg-row {
                        &:not(.flex-md-column) { // if hero half and half is set to text below on tablet: don't use these values
                            flex-direction: row !important;
                            @include media-breakpoint-only(md) { @include auto-size-hero-image-n-text-widths(-1); }
                        }
                        flex-direction: row !important;
                        @include media-breakpoint-up(lg) { @include auto-size-hero-image-n-text-widths(-1); }
                    }
                }
            }
        }
    }
    &:not(.half-and-half) {
        .hero-container {
            width: calc(min(100cqw, 100vw));
            .full-bleed & {
                width: 100vw;
                max-width: 100vw;
            }
            &.flex-column {
                @include media-breakpoint-down(sm) {
                    .hero-text { position: relative; }
                }
            }
            &.flex-md-column {
                @include media-breakpoint-only(md) {
                    .hero-text { position: relative; }
                }
            }
        }
        .hero-media {
            position: relative;
            transform: none;
            height: auto;
            top: auto;
            right: auto;
            left: auto;
            margin: 0;
            background: unset;
            width: 100cqw;
            .full-bleed & {
                width: 100vw;
            }
        }
        .hero-text {
            position: absolute;
        }
        &.align-item-center .hero-container { align-items: center; }
        &.align-item-end .hero-container{ align-items: flex-end; }
    }
}
