@import "~core/components/formFields";

.form-control, select.form-control{
    &.is-invalid {
        border-color: $form-validation-color;
        color: $form-validation-color;
    }
}

.custom-radio {
    &.selected {
        .custom-control-label {
            &:before {
                @include radio-icon-checked-background;
            }
            &:after {
                @include radio-icon-checked;
            }
        }
    }
    .custom-control-input {
        &.focus-visible,
        &:focus {
            ~ .custom-control-label {
                &:before {
                    outline: 2px solid $blue;
                    border-radius: 50%;
                }
            }
        }
    }
}