//OVERLAY DETAIL SELECTED
.product-list-enhancements-overlay-detail-selected {
    transition-property: all;
    transition-duration: $transition-speed;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transform: translateY(-100%);
    background-color: $white;
    position: absolute;
    opacity: 0;
    z-index: -1; //SAS FIX: hidden button placement
    &.open {
        transition-timing-function: ease-in;
        transform: translateY(0);
        opacity: 1;
        z-index: 0; //SAS FIX: hidden button placement
    }
}
